import { NavLink as NavLinkBase } from 'react-router-dom';
import { 
    Drawer,
    DrawerProps,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Toolbar,
    Box,
    Typography

}from '@mui/material';

import Dashboard from '@mui/icons-material/Dashboard';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import AccountSettingsIcon from '@mui/icons-material/Settings';
import Garage from '@mui/icons-material/Garage';
import Assessment from '@mui/icons-material/Assessment';
import Summarize from '@mui/icons-material/Summarize';
import TableRow from '@mui/icons-material/TableRows';
import FolderIcon from '@mui/icons-material/Folder';

import { useAuth } from "../../bancpass-lib";
import styled from "@emotion/styled";

const NavLink = styled(NavLinkBase)(({ theme }) => ({
  "display": "inline-block",
  "width": "100%",
  "&.active": {
    backgroundColor: "white"
  }
}))

const categories : CustomizedListItemData[] = [
  { 
    id: 'dashboard',
    name: 'Dashboard',
    icon: <Dashboard/>,
    path: "/"
  },
  {
    id: 'account',
    name: 'Account',
    children: [
      {
        id: 'tolls',
        icon: <TableRow/>,
        path: "/tolls",
        name: "Tolls"
      },
      {
        id: 'vehicleedit',
        icon: <Garage/>,
        path: "/vehicles",
        name: "Vehicles"
      },
    ],
  },
  {
    id: 'reports',
    name: 'Reports',
    icon: <Assessment/>,

    children: [
      {
        id: 'tollreport',
        icon: <Assessment/>,
        name: "Toll Report"
      },
      {
        id: 'statement',
        icon: <Summarize/>,
        path: "/statement",
        name: "Statement"
      },
    ],
  },
  {
    id: 'admin',
    name: "Administration",
    roles: ["ADMIN"],
    children: [
      { id: 'users', name: "Users", icon: <ManageAccountsIcon/>, path: "/users", roles: ["ADMIN"]},
      { id: 'documents', name: "Documents", icon: <FolderIcon/>, path: "/documents", roles: ["ADMIN"]},
      /*{ id: 'settings', name: "Settings", icon: <AccountSettingsIcon/>, path: "/settings"},*/
    ],
  },
];


interface CustomizedListItemData {
  id: string,
  name: string,
  path?: string,
  children?: CustomizedListItemData[],
  icon?: JSX.Element
  roles?: string[]
}

const CustomizedListItem = ( { item }: { item: CustomizedListItemData} ) => {
  const auth = useAuth();

    if (item.children !== undefined) {
      if (item.roles !== undefined) {
        if (item.roles.filter(value => auth?.userLoginRoles?.includes(value)).length == 0) { return (<></>); }
      }
        return (
            <div>
                <ListItem disablePadding sx={{paddingLeft:"8px"}}>
                    <ListItemText sx={{display:"inline-block"}} inset={false} secondary={item.name} />
                </ListItem>
                    <List disablePadding>
                        {item.children?.map(child => {
                            return (
                                <CustomizedListItem  key={child.id} item={child} />
                            )
                        })}
                    </List>
                </div>
        );
    } else if (item.path !== undefined) {
      if (item.roles !== undefined) {
        if (item.roles.filter(value => auth?.userLoginRoles?.includes(value)).length == 0) { return (<></>); }
      }
        return (
            <NavLink  style={({ isActive }) => ({ 
                        textDecoration: "none", 
                        color: isActive ? '#ffffff' : 'inherit',
                        background: isActive ? '#070744' : 'transparent',
                        borderRadius: isActive ? '0 25px 25px 0' : '0',
                        paddingRight: "-8px"
                    })} to={item.path} >
                <ListItem  sx={{paddingTop: "4px", paddingBottom: "4px"}} key={item.id} >
                    <ListItemIcon sx={{color: "inherit"}}>{item.icon}</ListItemIcon>
                    <ListItemText primaryTypographyProps={{lineHeight:"1"}}  primary={item.name} />
                </ListItem>
            </NavLink>

        );
    } else {
        // should not ever get here really 
        return (
                <ListItem sx={{paddingTop: "4px", paddingBottom: "4px"}} key={item.id} >
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.name} />

                </ListItem>
        );
    }
}


export default function Navigator(props: DrawerProps) {
    const { ...other } = props;
    const auth = useAuth();
  
    return <>{auth?.isAuthenticated ? (
         <Drawer variant="permanent" 
         sx={{
            width: 240,
            flexShrink: 0,
            zIndex: (theme) => theme.zIndex.drawer,
            [`& .MuiDrawer-paper`]: { backgroundColor: "lightgrey", width: 240, boxSizing: 'border-box' },
            ['& .MuiList-root']: { marginRight: "8px" },
          }}
         {...other}>
            <Toolbar/>
        <List>
          {categories.map(child => {
                    return (
                        <CustomizedListItem  key={child.id} item={child} />
                    )
                })}

          
        </List>
        <Box sx={{flex: "1 0 auto;"}}>
        </Box>
        <Box sx={{flex: "0 0 auto;"}}>
          <Typography align="center" sx={{color: "#00000060"}} variant="body2">{process.env.REACT_APP_PRETTYNAME} v{process.env.REACT_APP_VERSION}</Typography>
        </Box>
      </Drawer>
    ):<></>}</>
  }

