import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useSubmit } from "react-router-dom";
import { User, Vehicle, db } from "../../bancpass-lib";
import { getUsersByRole } from "../UserAdmin/getUser";

import { theme } from "../ui/Theme/Themes";

import {
    Autocomplete,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    IconButton,
    Input,
    MenuItem,
    Paper,
    Select,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";

import PauseIcon from "@mui/icons-material/Pause";
import PlayIcon from "@mui/icons-material/PlayArrow";
import SaveIcon from "@mui/icons-material/SaveAlt";
import { useLiveQuery } from "dexie-react-hooks";
import { doMergeVehicles } from "../../bancpass-lib/Network/mergeVehicles";
import { doPutVehicle } from "../../bancpass-lib/Network/putVehicle";

export const driverListQuery = () => ({
    queryKey: ["users", "DRIVER"],
    queryFn: () => getUsersByRole("DRIVER"),
});

const VehicleRow = (props: VehicleRowProps) => {
    const [vehicleTagPlateDialogOpen, setVehicleTagPlateDialogOpen] =
        useState(false);
    const [vehicleTagPlateId, setVehicleTagPlateId] = useState(-1);
    const [vehicleTagPlateNumber, setVehicleTagPlateNumber] = useState("");
    const [vehicleTagPlateSaving, setVehicleTagPlateSaving] = useState(false);
    const [vehicleTagPlateError, setVehicleTagPlateError] = useState<
        string | null
    >(null);

    const { data: drivers } = useQuery(driverListQuery());
    //const drivers_s = useLiveQuery(() => db.users.toArray());
    const drivers_s = useLiveQuery(() =>
        db.users
            .where("role")
            .anyOf(["DRIVER"])
            .and((user) => !user.deleted)
            .sortBy("last_name")
    );
    const untagged_active_vehicles_s = useLiveQuery(() =>
        db.vehicles
            .filter(
                (vehicle: Vehicle) =>
                    vehicle.tag == null && vehicle.plate.number != null
            )
            .and((vehicle: Vehicle) => vehicle.active)
            .toArray((vehicles) =>
                vehicles.sort((v_a: Vehicle, v_b: Vehicle) =>
                    v_a.plate.state < v_b.plate.state
                        ? -1
                        : v_a.plate.state > v_b.plate.state
                        ? 1
                        : v_a.plate.number < v_b.plate.number
                        ? -1
                        : v_a.plate.number > v_b.plate.number
                        ? 1
                        : 0
                )
            )
    );
    const [changed, setChanged] = useState(false);
    const [_vehicle, setVehicle] = useState(
        JSON.parse(JSON.stringify(props.vehicle))
    );
    const [driversList, setDriversList] = useState([{ label: "None", id: -1 }]);
    const [isSaving, setSaving] = useState(false);
    const [display, setDisplay] = useState("var(--active-display, grid)");

    const [autocompleteValue, setAutocompleteValue] = useState<{
        label: string;
        id: number;
    } | null>(
        driversList.find((d) => {
            return d.id === _vehicle.driver_id;
        }) || null
    );
    const submit = useSubmit();

    const handleSave = (event: any) => {
        setSaving(true);
        doPutVehicle(_vehicle)
            .then((resp: Vehicle) => {
                props.vehicle.user_def = resp.user_def;
                props.vehicle.driver_id = resp.driver_id;
                props.vehicle.driver_name = resp.driver_name;
            })
            .finally(() => {
                setSaving(false);
                setChanged(false);
            });
    };

    const handleVehicleTagPlateOK = () => {
        setVehicleTagPlateSaving(true);
        doMergeVehicles(vehicleTagPlateId, _vehicle.id)
            .then((resp: Vehicle) => {
                setVehicleTagPlateDialogOpen(false);
                setVehicleTagPlateError(null);
                setVehicleTagPlateId(-1);
                props.refetch();
            })
            .catch((error: Error) => {
                setVehicleTagPlateError(error.message);
            })
            .finally(() => {
                setVehicleTagPlateSaving(false);
            });
    };

    const handleToggleActivate = (event: any) => {
        setSaving(true);
        let newActive = !_vehicle.active;
        setVehicle({ ..._vehicle, active: newActive });
        let saveVehicle = JSON.parse(JSON.stringify(props.vehicle));
        saveVehicle.active = newActive;
        doPutVehicle(saveVehicle)
            .then((resp: Vehicle) => {
                props.vehicle.active = resp.active;
            })
            .finally(() => {
                setSaving(false);
                setChanged(false);
            });
    };

    useEffect(() => {
        if (
            _vehicle.driver_id !== props.vehicle.driver_id ||
            _vehicle.user_def !== props.vehicle.user_def
        ) {
            setChanged(true);
        } else {
            setChanged(false);
        }
        setAutocompleteValue(
            driversList.find((d) => {
                return d.id === _vehicle.driver_id;
            }) || null
        );
        if (!_vehicle.active) {
            setDisplay("var(--inactive-display, none)");
        } else {
            setDisplay("var(--active-display, grid)");
        }
    }, [_vehicle]);

    useEffect(() => {
        if (drivers_s == null) {
            return;
        }
        setVehicle({
            ..._vehicle,
            driver_id: autocompleteValue?.id || -1,
            driver_name: autocompleteValue?.label || "",
        });
    }, [autocompleteValue]);

    useEffect(() => {
        //console.log(drivers_s);
        if (drivers_s) {
            const list = drivers_s.map((u: User) => {
                return { label: u.first_name + " " + u.last_name, id: u.id };
            });
            setDriversList(list);
            setAutocompleteValue(
                list.find((d) => {
                    return d.id === _vehicle.driver_id;
                }) || null
            );
            return;
        }
    }, [drivers_s]);

    return (
        <>
            <Dialog
                open={vehicleTagPlateDialogOpen}
                onClose={() => {
                    if (!vehicleTagPlateSaving) {
                        setVehicleTagPlateDialogOpen(false);
                    }
                }}
            >
                <DialogTitle id="vehicle-tag-plate-dialog-title">
                    Assign tag to vehicle?
                </DialogTitle>
                <DialogContent>
                    {vehicleTagPlateError ? (
                        <DialogContentText
                            color="red"
                            id="vehicle-tag-plate-description"
                        >
                            {vehicleTagPlateError}
                        </DialogContentText>
                    ) : (
                        <DialogContentText id="vehicle-tag-plate-description">
                            Assign the tag '{props.vehicle.tag?.number}' to
                            license plate: '{vehicleTagPlateNumber}'? This
                            cannot be undone.
                        </DialogContentText>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        color="error"
                        disabled={vehicleTagPlateSaving}
                        onClick={() => setVehicleTagPlateDialogOpen(false)}
                        autoFocus
                    >
                        Cancel
                    </Button>
                    <Button
                        color="success"
                        disabled={
                            vehicleTagPlateSaving ||
                            vehicleTagPlateError != null
                        }
                        onClick={() => {
                            handleVehicleTagPlateOK();
                        }}
                    >
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
            <Paper
                sx={{
                    flex: `0 0 ${theme.spacing(8)}`,
                    display: { display },
                    borderColor: "#070744",
                    borderStyle: "solid",
                    borderWidth: "0px",
                    gridTemplateColumns: `${theme.spacing(
                        1
                    )} 150px auto auto 270px ${theme.spacing(
                        6
                    )} ${theme.spacing(6)} ${theme.spacing(1)}`,
                    gridTemplateRows: `${theme.spacing(1)} ${theme.spacing(
                        3
                    )} ${theme.spacing(3)} ${theme.spacing(1)}`,
                    overflow: "hidden",
                }}
            >
                {props.vehicle.plate.number != null ? (
                    <Typography
                        sx={{
                            fontSize: "1.2rem",
                            fontFamily: "Plat Nomor",
                            gridColumn: "2",
                            gridRow: "1",
                        }}
                    >
                        {props.vehicle.plate.state}-{props.vehicle.plate.number}
                    </Typography>
                ) : (
                    <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        onChange={(e) => {
                            if (e.target.value == "None") {
                                return;
                            }

                            let v_id = parseInt(e.target.value);

                            setVehicleTagPlateId(v_id);
                            let v = untagged_active_vehicles_s?.find(
                                (v: Vehicle) => v.id == v_id
                            );
                            if (!v) {
                                setVehicleTagPlateNumber("ERROR");
                            } else {
                                setVehicleTagPlateNumber(
                                    v.plate.state + "-" + v.plate.number
                                );
                            }
                            setVehicleTagPlateError(null);
                            setVehicleTagPlateSaving(false);
                            setVehicleTagPlateDialogOpen(true);
                        }}
                        value={"None"}
                        sx={{
                            fontSize: "1.2rem",
                            fontFamily: "Plat Nomor",
                            gridColumn: "2",
                            gridRowStart: "1",
                            gridRowEnd: "3",
                        }}
                    >
                        <MenuItem value="None">
                            <em>NO PLATE</em>
                        </MenuItem>
                        {untagged_active_vehicles_s?.map((v: Vehicle) => {
                            return (
                                <MenuItem value={v.id}>
                                    <em>
                                        {v.plate.state + "-" + v.plate.number}
                                    </em>
                                </MenuItem>
                            );
                        })}
                    </Select>
                )}
                {props.vehicle.tag != null ? (
                    <Typography
                        sx={{
                            fontSize: "1.2rem",
                            fontFamily: "Plat Nomor",
                            gridColumn: "3",
                            gridRow: "1",
                        }}
                    >
                        {props.vehicle.tag.number}
                    </Typography>
                ) : (
                    <></>
                )}

                <FormControl
                    sx={{
                        marginLeft: "4px",
                        height: "20px",
                        gridColumn: "2",
                        gridRow: "3",
                    }}
                    size="small"
                    variant="standard"
                >
                    <Input
                        value={_vehicle.user_def}
                        onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                            setVehicle({
                                ..._vehicle,
                                user_def: event.target.value,
                            });
                        }}
                        placeholder="Name"
                        id="userdef"
                    />
                </FormControl>
                <Autocomplete
                    disablePortal
                    size="small"
                    isOptionEqualToValue={(option, value) => {
                        return option.id === value.id;
                    }}
                    options={driversList}
                    value={autocompleteValue}
                    inputValue={autocompleteValue?.label || ""}
                    onChange={(
                        event: any,
                        newValue: { label: string; id: number | null } | null
                    ) => {
                        setVehicle({
                            ..._vehicle,
                            driver_id: newValue?.id || null,
                        });
                    }}
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        gridRowStart: "2",
                        gridRowEnd: "4",
                        gridColumnStart: "5",
                        gridColumnEnd: "6",
                    }}
                    renderInput={(params) => (
                        <TextField {...params} label="Driver" />
                    )}
                />
                {isSaving ? (
                    <CircularProgress
                        color="inherit"
                        size="48px"
                        sx={{
                            padding: "8px",
                            gridColumn: "6",
                            gridRowStart: "2",
                            gridRowEnd: "4",
                        }}
                    />
                ) : changed ? (
                    <Tooltip title="Save Changes">
                        <IconButton
                            onClick={(e) => handleSave(e)}
                            sx={{
                                gridColumn: "6",
                                gridRowStart: "2",
                                gridRowEnd: "4",
                            }}
                        >
                            <SaveIcon fontSize="large" />
                        </IconButton>
                    </Tooltip>
                ) : (
                    <IconButton
                        disabled
                        sx={{
                            gridColumn: "6",
                            gridRowStart: "2",
                            gridRowEnd: "4",
                        }}
                    >
                        <SaveIcon fontSize="large" />
                    </IconButton>
                )}
                <Tooltip
                    title={
                        props.vehicle.active
                            ? "Deactivate Vehicle"
                            : "Activate Vehicle"
                    }
                >
                    <IconButton
                        sx={{
                            gridColumn: "7",
                            gridRowStart: "2",
                            gridRowEnd: "4",
                        }}
                        onClick={(e) => handleToggleActivate(e)}
                    >
                        {props.vehicle.active ? (
                            <PauseIcon fontSize="large" />
                        ) : (
                            <PlayIcon fontSize="large" />
                        )}
                    </IconButton>
                </Tooltip>
            </Paper>
        </>
    );
};

interface VehicleRowProps {
    vehicle: Vehicle;
    refetch: () => void;
}

export { VehicleRow };

//                    inputValue={autocompleteValue?.label || ''}
/*
 */
