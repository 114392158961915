import { waitForAxiosInstance } from "../HttpCommon";
import { Config } from "../HttpCommon";
import { AxiosError, AxiosInstance } from "axios";
import { User } from "../Types";
export async function doUpdateUserLogin(user: User, newPassword: string | null | undefined = null, oldPassword: string | null | undefined = null): Promise<any> {
    return waitForAxiosInstance().then((inst : AxiosInstance) => {
        return inst.post("/updateUserLogin", {
            params: {
                userLogin: user,
                newPassword: newPassword,
                oldPassword: oldPassword
            },
        }, { authRequired: true, withCredentials: true } as Config)
            .then((response: any) => {
                if (response.data.code < 0) {
                    return Promise.reject(new Error(response.data.desc));
                }
                let users: User[] = response.data.response.userLogins;
                return Promise.resolve(users);
            })
            .catch((e: Error) => {
                console.log(e.message);
                if (e instanceof AxiosError) {
                    return Promise.reject(new Error("Network error.  Please retry.  If issue persists, contact BancPass support."));
                } else {
                    return Promise.reject(e);
                }
            });
    });
}
