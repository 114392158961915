import {
  IconButton,
  Paper,
  Skeleton,
  ToggleButton,
  ToggleButtonGroup,
  Toolbar,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import { useSearchParams } from "react-router-dom";


import { Box } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import {
  useSubmit,
} from "react-router-dom";
import { useDebounce } from "rooks";
import { Vehicle } from "../../bancpass-lib";
import { useNavigationSettersContext } from "../AppNavigationBar/NavigationContext";
import { SearchBar } from "../ui/SearchBar/SearchBar";
import { theme } from "../ui/Theme/Themes";
import { AddNewVehicleDialog } from "./AddNewVehicleDialog";
import { VehicleRow } from "./VehicleRow";
import { getVehicles } from "./getVehicle";

const vehicleListQuery = (q : string | null) => ({
  queryKey: ["vehicles", "list", q ?? "all"],
  queryFn: () => getVehicles(q),
});

const activeVars = {
  '--active-display': 'grid' ,
  '--inactive-display': 'none'
} as React.CSSProperties;

const inactiveVars = {
  '--inactive-display': 'grid' ,
  '--active-display': 'none'
} as React.CSSProperties;

export function VehicleAdmin(): any {
  const [searchParams, setSearchParams] = useSearchParams(); // eslint-disable-line @typescript-eslint/no-unused-vars
  const [q, setQ] = useState(searchParams.get("q"));
  const { data: vehicles, isLoading, isFetching, refetch } = useQuery(vehicleListQuery(q));
  const [vars, setVars] = useState<React.CSSProperties>(activeVars);
  const [addVehicleDialogOpen, setAddVehicleDialogOpen] = useState(false);


  const submit = useSubmit();
  const debouncedSubmit = useDebounce(submit, 500);
  const [active, setActive] = useState("active");
  const navContext = useNavigationSettersContext();

  useEffect(() => {
    (async function () {
      setQ(searchParams.get("q"));
    })();
  }, [searchParams]);

 
  useEffect(() => {
    navContext?.setPageTitle("Vehicle Administration");
  }, [navContext]);


  const handleAddVehicleDialogClose = (event: object|null, reason: string) => {
    setAddVehicleDialogOpen(false);
    if (reason === "success") { // refresh our shit
      refetch();
    }
  };

  const doRefetch = () => {
    refetch();
  };

  const handleChangeActive = (event: any, newActive: string) => {
    if (newActive !== null) {
      setActive(newActive);
      if (newActive === "active") {
        setVars(activeVars);
      } else {
        setVars(inactiveVars);
      }
    }
  }

  return (<>
    <Box style={vars} className="pageRoot" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>

      <Toolbar sx={{ flex: "0 0 1px" }} />
      <Box sx={{
        flexGrow: 0, boxSizing: "border-box", paddingLeft: "24px", paddingBottom: "8px",
        gap: "8px", width: "100%", display: "flex", paddingRight: "24px"
      }} id="topbar">
        <form style={{ flexGrow: 1 }} id="vehicle-admin-search" role="search">
          <SearchBar
            sx={{ flexGrow: 1 }}
            autoFocus id="q"
            aria-label="Search Vehicles"
            name="q"
            key={q}
            value={q ? q : ''}
            onCancelSearch={() => { submit({ q: "" }); }}
            onRequestSearch={(val: string) => { }}
            onChange={(event) => {
              debouncedSubmit(event.currentTarget.form);
            }}
          />
        </form>
        <ToggleButtonGroup
          sx={{ height: theme.spacing(6) }}
          color="primary"
          value={active}
          exclusive
          onChange={handleChangeActive}
          aria-label="Active"
        >
          <ToggleButton value="active">Active</ToggleButton>
          <ToggleButton value="inactive">Inactive</ToggleButton>
        </ToggleButtonGroup>

        <Paper sx={{ height: theme.spacing(6) }}>
          <IconButton onClick={(e) => { setAddVehicleDialogOpen(true); }} sx={{ margin: "3px" }}><AddIcon /></IconButton>
        </Paper>
      </Box>


      <Box sx={{ width: "100%", flexShrink: "1", display: "flex", gap: "12px", padding: "8px", flexDirection: "column", overflowY: "auto" }}>
        {(isLoading || isFetching) ? (<>

          {[...Array(7)].map((_, k) => (
            <Paper key={k} sx={{
              flex: `0 0 ${theme.spacing(8)}`, display: "grid",
              borderColor: "#070744", borderStyle: "solid", borderWidth: "0px",
              gridTemplateColumns: `${theme.spacing(1)} 150px auto auto 270px ${theme.spacing(6)} ${theme.spacing(6)} ${theme.spacing(1)}`,
              gridTemplateRows: `${theme.spacing(1)} ${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(1)}`,
              overflow: "hidden"
            }}>
              <Skeleton sx={{ gridColumn: "2", gridRow: "2" }} />
              <Skeleton sx={{ gridColumn: "2", gridRow: "3" }} />
              <Skeleton sx={{ gridRowStart: "2", gridRowEnd: "4", gridColumnStart: "5", gridColumnEnd: "6" }} />
            </Paper>
          ))}
        </>) : vehicles?.length ? (
          <>
            {vehicles.map((vehicle: Vehicle) => (
              <VehicleRow key={vehicle.id} vehicle={vehicle} refetch={doRefetch}/>
            ))}

          </>
        ) : (
          <p>
            <i>No matching vehicles.</i>
          </p>
        )}

      </Box>


    </Box>
    <AddNewVehicleDialog open={addVehicleDialogOpen} onClose={handleAddVehicleDialogClose}/>
    
  </>
  )
}

