import { waitForAxiosInstance } from "../HttpCommon";
import { Config } from "../HttpCommon";
import { AxiosError, AxiosInstance } from "axios";
import { DateTime } from "luxon";
import { StatementRow } from "../Types";



export async function doGetStatement(rowOffset: number | null, rowsPerPage: number | null, startTime: number | null, endTime: number | null, vehicle_id: number | null | undefined): Promise<any> {
    return waitForAxiosInstance().then((inst : AxiosInstance) => {
        return inst.post("/getStatement", {
            params: {
                rowOffset: rowOffset,
                rowsPerPage: rowsPerPage,
                startTime: startTime,
                endTime: endTime,
                vehicle_id: vehicle_id,
            },
        }, { authRequired: true, withCredentials: true } as Config)
            .then((response: any) => {
                if (response.data.code < 0) {
                    return Promise.reject(new Error(response.data.desc));
                }
                console.log(response.data);
                let statementRows: StatementRow[] = response.data.response.statement;
               
                return Promise.resolve([statementRows, response.data.response.fullcount]);
            })
            .catch((e: Error) => {
                console.log(e.message);
                if (e instanceof AxiosError) {
                    return Promise.reject(new Error("Network error.  Please retry.  If issue persists, contact BancPass support."));
                } else {
                    return Promise.reject(e);
                }
            });
    });
}

