import {
    Toolbar,
    Table, TableBody, TableCell, TableHead, TableContainer, TablePagination, TableRow,
    TextField,
    Button,
    IconButton,
    Skeleton,
    Typography,
    Dialog, DialogTitle, DialogContent, DialogActions
} from "@mui/material";

import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';



import LastPageIcon from "@mui/icons-material/LastPage";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDebounce } from "rooks";
import { theme } from "../ui/Theme/Themes";
import { TollRow, TollRowColumns } from "./TollRow";
import {
    Await,
    defer,
    useLoaderData,
    NavLink,
    useSubmit,
    useFetcher
  } from "react-router-dom";
  import { useQuery, QueryClient, Query, UseQueryOptions } from "@tanstack/react-query";
  import { Box } from "@mui/material";
  import { Vehicle, Trip, User } from "../../bancpass-lib";
import { useNavigationSettersContext } from "../AppNavigationBar/NavigationContext";
import { DateTime, Duration } from "luxon";
import { doGetTollPoints } from "../../bancpass-lib/Network/getTollPoints";
import { doGetTrips } from "../../bancpass-lib/Network/getTrips";

import { Loader } from '@googlemaps/js-api-loader';
import { isAbsolute, relative } from "path";

const loader = new Loader({
  apiKey: "AIzaSyAGC-lfLq1zRONJZ7VoGWfYx11p8yD_gMo",
  version: "weekly",
});
//AIzaSyCF2PmuqMX8D_Y7Y7G3XEbxt0boQB9gVLw


interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number,
  ) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}




const tollPointsQuery = (): UseQueryOptions => ({
  queryKey: ["tollpoints"],
  queryFn: () => doGetTollPoints(null),
  staleTime: 1000 * 60 * 60 * 24,

});

const tollListQuery = (startTime: number, endTime: number): UseQueryOptions<Trip[]> => ({
  queryKey: ["tolls", "all", startTime, endTime],
  queryFn: () => doGetTrips(startTime, endTime, null),
});


export function TollPage(): any {
  const [ searchParams, setSearchParams ] = useSearchParams();
  const st_secs = Number(searchParams.get("start"));
  const end_secs = Number(searchParams.get("end"));

  const [startTime, setStartTime] = useState<DateTime>(
    st_secs ? DateTime.fromSeconds(st_secs) : 
              DateTime.now().minus(Duration.fromObject({ months: 1 })).startOf('month'));
  
  const [endTime, setEndTime] = useState<DateTime>(
    end_secs ? DateTime.fromSeconds(end_secs) : 
    DateTime.now().endOf('day')
  );

  const [startValue, setStartValue] = useState(startTime.toSeconds());
  const [endValue, setEndValue] = useState(endTime.toSeconds());
  const { data: trips, isLoading, isFetching } = useQuery(tollListQuery(startValue, endValue));

  const submit = useSubmit();
  const navContext = useNavigationSettersContext();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  useEffect(() => {
    navContext?.setPageTitle("Tolls");
  },[navContext]);

  const handleStartTimeChange = (newValue: DateTime | null) => {
    if (!newValue) {
        setStartTime(DateTime.now().minus(Duration.fromObject({ months: 1 })).startOf('month'));
        //setStartValue(DateTime.now().minus(Duration.fromObject({ months: 1 })).startOf('month').toSeconds());
      } else {
        setStartTime(newValue);
        //setStartValue(newValue.toSeconds());
    }
  };

  const handleEndTimeChange = (newValue: DateTime | null) => {
    if (!newValue) {
        setEndTime(DateTime.now().endOf('day'));
        //setEndValue(DateTime.now().endOf('day').toSeconds());
    } else {
        setEndTime(newValue);
        //setEndValue(newValue.toSeconds());
    }
  };

  const handleSave = (event: any, v: Vehicle) => {
    console.log(event);
    console.log(v);
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [mapDialogOpen, setMapDialogOpen] = useState(false);
  const [mapDialogTitle, setMapDialogTitle] = useState("");


  const openMapDialog = (title: string, lat: number, long: number) => {
    setMapDialogTitle(title);

    const mapOptions = {
      center: {
        lat: lat,
        lng: long,
      },
      zoom: 15
    };
    
    loader
      .load()
      .then((google) => {
        const map = new google.maps.Map(document.getElementById("map")!, mapOptions);
          // The marker, positioned at Uluru
          const marker = new google.maps.Marker({
            position: { lat: lat, lng: long },
            map: map,
          });
      })
      .catch(e => {
        // do something
      });
    setMapDialogOpen(true);
  };

  const handleMapDialogClose = () => {
    setMapDialogOpen(false);
  };


  return (<>
    <Box className="pageRoot" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>

      <Toolbar sx={{ flex: "0 0 1px" }} />
     
      <Box component="form" sx={{
        flexGrow: 0, boxSizing: "border-box", paddingLeft: "24px", paddingBottom: "8px",
        gap: "8px", width: "100%", display: "flex", paddingRight: "24px"
      }} id="topbar" role="search">
        <input name="start" type="hidden" value={startValue}/>
        <input name="end" type="hidden" value={endValue}/>

        <DateTimePicker
          label="Start Time"
          
          value={startTime}
          onChange={handleStartTimeChange}
          renderInput={(params) => <TextField {...params} />}
        />
        <DateTimePicker
          label="End Time"
          value={endTime}
          onChange={handleEndTimeChange}
          renderInput={(params) => <TextField {...params} />}
        />
        
        <Box sx={{flexGrow:"1"}}/>
                <Button onClick={(event) => { 
                  setStartValue(startTime.toSeconds());
                  setEndValue(endTime.toSeconds());
              submit(event.currentTarget.form);
            }} variant="contained">Search</Button>    
       
      </Box>
     
      <TableContainer sx={{ flex: "0 1 100vh" }}>
        <Table stickyHeader sx={{ height: "100%" }} aria-label="sticky table">
          <TableHead>
            <TableRow>
              {TollRowColumns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  sx={{ padding: "4px 16px", fontWeight: "bold" }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>


          {(isLoading || isFetching) ? <><TableBody sx={{ backgroundColor: "white" }}>

            {[...Array(10)].map((_, k) => (
              <TableRow key={k}>
                {[...Array(TollRowColumns.length)].map((_, l) => (
                <TableCell key={l}><Skeleton sx={{ fontSize: '0.5rem' }} variant="text" /></TableCell>
                ))}
              </TableRow>
            ))
            }


          </TableBody>
          </> :



            trips?.length ? <TableBody sx={{ backgroundColor: "white" }}>
              {trips.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((trip) => {
                  return (
                    <TollRow key={trip.id} trip={trip} mapOpen={openMapDialog} />
                  );
                })}
            </TableBody>
              :

              <TableBody sx={{ backgroundColor: "white", position: "relative" }}>
                <Box sx={{
                  position: "absolute", width: "100%", height: "100%", backgroundColor: "white",
                  textAlign: "center"
                }}>No Tolls</Box>
              </TableBody>
          }
        </Table>
      </TableContainer>
      <TablePagination sx={{ flex: "1 0 auto", padding: 0 }}
              rowsPerPageOptions={[50, 100, 250, { label: 'All', value: -1 }]}
              colSpan={3}
              count={trips?.length || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
              ActionsComponent={TablePaginationActions}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
      </Box>
      <Dialog maxWidth="xl"
        open={mapDialogOpen}
        onClose={handleMapDialogClose}
      >
        <DialogTitle id="alert-dialog-title">{mapDialogTitle}</DialogTitle>
        <DialogContent id="map" sx={{width: "75vw", height: "80vh"}}>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleMapDialogClose} variant="outlined">OK</Button>
        </DialogActions>
      </Dialog>
</>
  )
}

/*ActionsComponent={TablePaginationActions}*/
