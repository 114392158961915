import { waitForAxiosInstance } from "../HttpCommon";
import { Config } from "../HttpCommon";
import { AxiosError, AxiosInstance } from "axios";
import { DateTime } from "luxon";
import { StatementSummary } from "../Types";

export async function doGetStatementSummary(): Promise<any> {
    return waitForAxiosInstance().then((inst : AxiosInstance) => {
        return inst.post("/getStatementSummary", {
            params: {
            },
        }, { authRequired: true, withCredentials: true } as Config)
            .then((response: any) => {
                console.log(response);
                if (response.data.code < 0) {
                    return Promise.reject(new Error(response.data.desc));
                }
                let summary: StatementSummary = response.data.response.summary;
                
                return Promise.resolve(summary);
            })
            .catch((e: Error) => {
                console.log(e.message);
                if (e instanceof AxiosError) {
                    return Promise.reject(new Error("Network error.  Please retry.  If issue persists, contact BancPass support."));
                } else {
                    return Promise.reject(e);
                }
            });
    });
}

