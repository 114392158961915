import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
    Navigate,
    Outlet,
    Route,
    createBrowserRouter,
    createRoutesFromElements,
    useLocation,
} from "react-router-dom";
import AppNavigationBar from "./components/AppNavigationBar/AppNavigationBar";
import Dashboard from "./components/Dashboard/Dashboard";
import Settings from "./components/Settings/Settings";
import { UserAdmin } from "./components/UserAdmin/UserAdmin";
import { VehicleAdmin } from "./components/VehicleAdmin/VehicleAdmin";

import { TollPage } from "./components/Tolls/TollPage";
import { UserCard } from "./components/UserAdmin/UserCard";
import LoginPage from "./components/pages/LoginPage/LoginPage";
import LogoutPage from "./components/pages/LogoutPage/LogoutPage";

import { Box } from "@mui/material";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AuthProvider, useAuth } from "./bancpass-lib";
import { NavigationContextProvider } from "./components/AppNavigationBar/NavigationContext";
import DocumentPage from "./components/Documents/DocumentPage";
import Navigator from "./components/Navigator/Navigator";
import { StatementPage } from "./components/Statement/StatementPage";
import ChangePasswordPage from "./components/pages/ChangePasswordPage/ChangePasswordPage";
import ForgotPasswordPage from "./components/pages/ForgotPasswordPage/ForgotPasswordPage";

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 1000 * 60 * 1,
            refetchOnWindowFocus: false, // default: true
        },
    },
});

function RequireAuth({ children }: { children: any }) {
    const authed = useAuth()?.isAuthenticated;
    const location = useLocation();
    return authed === true ? (
        children
    ) : (
        <Navigate to="/login" replace state={{ path: location.pathname }} />
    );
}

function RequireNoAuth({ children }: { children: any }) {
    const authed = useAuth()?.isAuthenticated;
    const location = useLocation();

    return authed === false ? (
        children
    ) : (
        <Navigate to="/" replace state={{ path: location.pathname }} />
    );
}

const AuthLayout = () => (
    <AuthProvider>
        <Outlet />
    </AuthProvider>
);

const NavigationContextLayout = () => (
    <NavigationContextProvider>
        <Outlet />
    </NavigationContextProvider>
);

const PersistQueryClientProviderLayout = () => (
    <QueryClientProvider client={queryClient}>
        <Outlet />
    </QueryClientProvider>
);

/*
const QueryClientProviderLayout = () => (
  <QueryClientProvider client={queryClient}><Outlet/></QueryClientProvider>
);
*/

const AppNavigationBarLayout = () => (
    <Box sx={{ display: "flex" }}>
        <AppNavigationBar />
        <Navigator />
        <Outlet />
    </Box>
);

const LocalizationProviderLayout = () => (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
        <Outlet />
    </LocalizationProvider>
);

export const router = createBrowserRouter(
    createRoutesFromElements(
        <>
            <Route element={<AuthLayout />}>
                <Route element={<PersistQueryClientProviderLayout />}>
                    <Route element={<NavigationContextLayout />}>
                        <Route element={<LocalizationProviderLayout />}>
                            <Route element={<AppNavigationBarLayout />}>
                                <Route
                                    path="/"
                                    element={
                                        <RequireAuth>
                                            <Dashboard />
                                        </RequireAuth>
                                    }
                                />
                                <Route
                                    path="users"
                                    element={
                                        <RequireAuth>
                                            <UserAdmin />
                                        </RequireAuth>
                                    }
                                >
                                    <Route
                                        path=":userLoginId"
                                        element={<UserCard />}
                                    />
                                </Route>
                                <Route
                                    path="vehicles"
                                    element={
                                        <RequireAuth>
                                            <VehicleAdmin />
                                        </RequireAuth>
                                    }
                                />
                                <Route
                                    path="tolls"
                                    element={
                                        <RequireAuth>
                                            <TollPage />
                                        </RequireAuth>
                                    }
                                />
                                <Route
                                    path="statement"
                                    element={
                                        <RequireAuth>
                                            <StatementPage />
                                        </RequireAuth>
                                    }
                                />
                                <Route
                                    path="documents"
                                    element={
                                        <RequireAuth>
                                            <DocumentPage />
                                        </RequireAuth>
                                    }
                                />

                                <Route
                                    path="settings"
                                    element={
                                        <RequireAuth>
                                            <Settings />
                                        </RequireAuth>
                                    }
                                />
                                <Route
                                    path="login"
                                    element={
                                        <RequireNoAuth>
                                            <LoginPage />
                                        </RequireNoAuth>
                                    }
                                />
                                <Route
                                    path="forgot-password"
                                    element={
                                        <RequireNoAuth>
                                            <ForgotPasswordPage />
                                        </RequireNoAuth>
                                    }
                                />

                                <Route
                                    path="change-password"
                                    element={
                                        <RequireNoAuth>
                                            <ChangePasswordPage />
                                        </RequireNoAuth>
                                    }
                                />

                                <Route path="logout" element={<LogoutPage />} />
                            </Route>
                            <Route
                                path="*"
                                element={<Navigate replace to="/" />}
                            />
                        </Route>
                    </Route>
                </Route>
            </Route>
        </>
    ),
    { basename: process.env.REACT_APP_PUBLIC_URL }
);
//loader={UserAdminLoader(queryClient)}
/*
function App() {
  return (
    <Box sx={{ display: 'flex' }}>
    <AuthProvider>
        <CssBaseline/>
      <AppNavigationBar/>
      <Navigator/>

       
  
    </Box>
  );
} 
*/
