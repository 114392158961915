import { waitForAxiosInstance } from "../HttpCommon";
import { Config } from "../HttpCommon";
import { AxiosError, AxiosInstance } from "axios";
import { User } from "../Types";



export function getDefaultUser(): User {
    console.log("getDefaultUser()");
    return { id: -1, 
             email_address: '', 
             first_name: '', 
             last_name: '', 
             added_time: Date.now(), 
             role:[], 
             canlogin: false,
             deleted: false};
}

//setAuthToken: React.Dispatch<React.SetStateAction<string | null>>;
//setAuthTokenIssued: React.Dispatch<React.SetStateAction<number | null>>;

export function handleChangeUser(user: User, field: string, value: any): User {
    switch (field) {
        case "email_address":
            user.email_address = value;
            break;
        case "first_name":
            user.first_name = value;
            break;
        case "last_name":
            user.last_name = value;
            break;
        
    }                        
    return user;
}

export async function doGetUserLogins(ulid: number = -1): Promise<any> {
    return waitForAxiosInstance().then((inst : AxiosInstance) => {
        return inst.post("/getUserLogins", {
            params: {
                user_login_id: ulid,
            },
        }, { authRequired: true, withCredentials: true } as Config)
            .then((response: any) => {
                if (response.data.code < 0) {
                    return Promise.reject(new Error(response.data.desc));
                }
                let users: User[] = response.data.response.user_logins;
                return Promise.resolve(users);
            })
            .catch((e: Error) => {
                console.log(e.message);
                if (e instanceof AxiosError) {
                    return Promise.reject(new Error("Network error.  Please retry.  If issue persists, contact BancPass support."));
                } else {
                    return Promise.reject(e);
                }
            });
    });
}

