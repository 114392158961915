import { waitForAxiosInstance } from "../HttpCommon";
import { Config } from "../HttpCommon";
import { AxiosError, AxiosInstance } from "axios";
import { Vehicle } from "../Types";

export async function doPutVehicle(vehicle: Vehicle): Promise<Vehicle> {
    return waitForAxiosInstance().then((inst : AxiosInstance) => {
        return inst.post("/updateVehicle", {
            params: {
                vehicle: vehicle,
            },
        }, { authRequired: true, withCredentials: true } as Config)
            .then((response: any) => {
                if (response.data.code < 0) {
                    return Promise.reject(new Error(response.data.desc));
                }
                let vehicle: Vehicle = response.data.response.vehicle;
                return Promise.resolve(vehicle);
            })
            .catch((e: Error) => {
                console.log(e.message);
                if (e instanceof AxiosError) {
                    return Promise.reject(new Error("Network error.  Please retry.  If issue persists, contact BancPass support."));
                } else {
                    return Promise.reject(e);
                }
            });
    });
}

