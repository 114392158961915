import { matchSorter } from 'match-sorter';
import { User, db } from '../../bancpass-lib';
import { doGetUserLogins, getDefaultUser } from '../../bancpass-lib/Network/getUserLogins';
var sortBy = require('sort-by');

/*
const seed = async () => {
  const initialData = [
    {
      avatar: "https://avatars.githubusercontent.com/u/5580297?v=4",
      createdAt: 1660978713047,
      favorite: false,
      first: "Tanner",
      id: "usupkc1",
      last: "Linsley",
      notes: "Created React Query",
      twitter: "@tannerlinsley",
    },
    {
      avatar: "https://avatars.githubusercontent.com/u/1021430",
      createdAt: 1660979124264,
      favorite: false,
      first: "Dominik",
      id: "kvvztl7",
      last: "D",
      notes: "Maintains React Query",
      twitter: "@tkdodo",
    },
  ];
  //const contacts = await localforage.getItem("contacts");
  if (!contacts) {
    set(initialData);
  }
};

seed();
*/



export async function getUsers(query: string | null): Promise<User[]> {
  return doGetUserLogins().then((response: User[]) => {
    //var users;
    //if (!users)
    let users = response;

    if (query) {
      users = matchSorter(users, query, { keys: ["first_name", "last_name"] });
      console.log(users);
    }
    db.users.bulkPut(users);

    return Promise.resolve(users.sort(sortBy("last_name", "added_time")));
  }).catch((e: Error) => {
    return Promise.reject(e);
  });
}

export async function getUsersByRole(role: string): Promise<User[]> {
  return doGetUserLogins().then((response: User[]) => {
    let users = response;
    users = users.filter(function(u) { return u.role.includes(role) && !u.deleted;});
    users = users.sort(sortBy("last_name", "first_name"));
    console.log(users);
    return Promise.resolve(users);
  }).catch((e: Error) => {
    return Promise.reject(e);
  });
}

export async function createUser() {
  let user: User = getDefaultUser();
  let users = await getUsers(null);
  users.unshift(user);
  return user;
}

export async function getUser(id: number) {
  console.log("getUser()");
  return doGetUserLogins(id).then((response: User[]) => {
    let user = response[0];
    return Promise.resolve(user);
  }).catch((e: Error) => {
    return Promise.reject(e);
  });
}

export async function updateUser(id: number, updates: User) {
  let users = await getUsers(null);
  let user = users.find((user) => user.id === id);
  if (!user) throw new Error("No contact found for"+ id.toString());
  Object.assign(user, updates);
  //await set(contacts);
  return user;
}

export async function deleteUser(id: number) {
  let users = await getUsers(null);
  let index = users.findIndex((user) => user.id === id);
  if (index > -1) {
    users.splice(index, 1);
    return true;
  }
  return false;
}

