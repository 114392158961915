import AddIcon from "@mui/icons-material/Add";
import {
    Avatar,
    Chip,
    IconButton,
    Paper,
    Skeleton,
    Stack,
    Toolbar,
    Typography,
} from "@mui/material";
import { QueryClient, useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { NavLink, Outlet, useSearchParams, useSubmit } from "react-router-dom";
import { useDebounce } from "rooks";
import { User } from "../../bancpass-lib";
import { theme } from "../ui/Theme/Themes";
import { createUser, getUsers } from "./getUser";
import { stringAvatar } from "./stringAvatar";

import { Box } from "@mui/material";
import { useNavigationSettersContext } from "../AppNavigationBar/NavigationContext";
import { SearchBar } from "../ui/SearchBar/SearchBar";

const userListQuery = (q: string | null) => ({
    queryKey: ["user_logins", "list", q ?? "all"],
    queryFn: () => getUsers(q),
});

export const action = (queryClient: QueryClient) => async () => {
    const contact = await createUser();
    queryClient.invalidateQueries({ queryKey: ["contacts", "list"] });
    return contact;
};

export function UserAdmin(): any {
    const [searchParams, setSearchParams] = useSearchParams();
    const [q, setQ] = useState(searchParams.get("q"));
    const { data: users, isLoading, isFetching } = useQuery(userListQuery(q));
    const submit = useSubmit();
    const debouncedSubmit = useDebounce(submit, 500);
    const navContext = useNavigationSettersContext();
    useEffect(() => {
        navContext?.setPageTitle("User Administration");
    }, [navContext]);

    useEffect(() => {
        (async function () {
            setQ(searchParams.get("q"));
        })();
    }, [searchParams]);

    return (
        <>
            <Box
                sx={{
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                    width: "100%",
                    height: "100vh",
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <Toolbar sx={{ flex: "0 0 1px" }} />
                <Box
                    sx={{
                        flexGrow: 0,
                        boxSizing: "border-box",
                        paddingLeft: "24px",
                        width: "100%",
                        display: "flex",
                        paddingRight: "24px",
                    }}
                    id="topbar"
                >
                    <form
                        style={{ flexGrow: 1 }}
                        id="search-form"
                        role="search"
                    >
                        <SearchBar
                            sx={{ flexGrow: 1 }}
                            autoFocus
                            id="q"
                            aria-label="Search User Logins"
                            name="q"
                            value={q ? q : ""}
                            onCancelSearch={() => {
                                setQ("");
                            }}
                            onRequestSearch={(val: string) => {}}
                            onChange={(event) => {
                                debouncedSubmit(event.currentTarget.form);
                            }}
                        />
                    </form>
                    <Paper
                        sx={{ marginLeft: "24px", height: theme.spacing(6) }}
                    >
                        <NavLink to={`create`}>
                            <IconButton sx={{ margin: "3px" }}>
                                <AddIcon />
                            </IconButton>
                        </NavLink>
                    </Paper>
                </Box>
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        flex: "1",
                        minHeight: "0",
                        flexDirection: "row",
                        boxSizing: "border-box",
                        padding: "24px",
                    }}
                >
                    <Box
                        sx={{
                            width: "33%",
                            flexShrink: "0",
                            display: "flex",
                            gap: "12px",
                            paddingLeft: "8px",
                            paddingRight: "8px",
                            flexDirection: "column",
                            overflowY: "auto",
                        }}
                    >
                        {users?.length ? (
                            <>
                                {users.map((user: User) => (
                                    user.deleted ? <></> : 
                                    <NavLink
                                        key={user.id}
                                        style={{
                                            textDecoration: "none",
                                            color: "inherit",
                                        }}
                                        to={`${user.id}?q=${q ? q : ""}`}
                                        className={({
                                            isActive,
                                            isPending,
                                        }) => {
                                            return isActive
                                                ? "active"
                                                : isPending
                                                ? "pending"
                                                : "";
                                        }}
                                    >
                                        {({ isActive }) => (
                                            <Paper
                                                elevation={isActive ? 8 : 1}
                                                sx={{
                                                    flex: "0 0 175px",
                                                    display: "grid",
                                                    borderColor: "#070744",
                                                    borderStyle: "solid",
                                                    borderWidth: isActive
                                                        ? "2px"
                                                        : "0px",
                                                    gridTemplateColumns:
                                                        "8px 50px auto 50px 8px",
                                                    gridTemplateRows:
                                                        "8px auto auto auto 8px",
                                                    overflow: isActive
                                                        ? "visible"
                                                        : "hidden",
                                                }}
                                            >
                                                <Avatar
                                                    {...stringAvatar(
                                                        user.first_name,
                                                        user.last_name,
                                                        {
                                                            alignSelf: "start",
                                                            gridColumn: "2",
                                                            gridRowStart: "2",
                                                            gridRowEnd: "4",
                                                        }
                                                    )}
                                                />
                                                <Typography
                                                    sx={{
                                                        gridColumn: "3",
                                                        gridRow: "1",
                                                    }}
                                                >
                                                    {user.first_name ||
                                                    user.last_name ? (
                                                        <>
                                                            {user.first_name}{" "}
                                                            {user.last_name}
                                                        </>
                                                    ) : (
                                                        <i>No Name</i>
                                                    )}{" "}
                                                </Typography>
                                                <Box
                                                    sx={{
                                                        gridColumnStart: "1",
                                                        marginLeft: "-1px",
                                                        backgroundColor:
                                                            user.canlogin
                                                                ? "green"
                                                                : "transparent",
                                                        gridRowStart: "1",
                                                        gridRowEnd: "6",
                                                        width: "5px",
                                                    }}
                                                />
                                                <Typography
                                                    sx={{
                                                        gridColumnStart: "3",
                                                        gridColumnEnd: "5",
                                                        overflow: "hidden",
                                                        textOverflow:
                                                            "ellipsis",
                                                        gridRow: "3",
                                                        fontWeight: "bold",
                                                        fontSize: "0.8rem",
                                                    }}
                                                >
                                                    {user.email_address
                                                        ? user.email_address
                                                        : ""}
                                                </Typography>
                                                <Stack
                                                    sx={{
                                                        gridColumnStart: "3",
                                                        gridColumnEnd: "5",
                                                        gridRow: "4",
                                                        alignSelf: "end",
                                                    }}
                                                    direction="row-reverse"
                                                    spacing={1}
                                                >
                                                    {user.role?.length ? (
                                                        user.role.map(
                                                            (role: string) => (
                                                                <Chip
                                                                    size="small"
                                                                    sx={{
                                                                        fontSize:
                                                                            "0.75rem",
                                                                        height: "18px",
                                                                    }}
                                                                    label={role}
                                                                    key={role}
                                                                />
                                                            )
                                                        )
                                                    ) : (
                                                        <></>
                                                    )}
                                                </Stack>
                                            </Paper>
                                        )}
                                    </NavLink>
                                ))}
                            </>
                        ) : isFetching || isLoading ? (
                            <>
                                {[...Array(4)].map((_, k) => (
                                    <Paper
                                        key={k}
                                        sx={{
                                            flex: "0 0 74px",
                                            display: "grid",
                                            borderColor: "#070744",
                                            borderStyle: "solid",
                                            borderWidth: "0px",
                                            gridTemplateColumns:
                                                "8px 50px auto 50px 8px",
                                            gridTemplateRows:
                                                "8px 20px 20px 20px 8px",
                                            overflow: "hidden",
                                        }}
                                    >
                                        <Skeleton
                                            variant="circular"
                                            sx={{
                                                alignSelf: "start",
                                                gridColumn: "2",
                                                gridRowStart: "2",
                                                gridRowEnd: "4",
                                                height: "50px",
                                            }}
                                        />
                                        <Skeleton
                                            sx={{
                                                marginLeft: "8px",
                                                gridColumn: "3",
                                                gridRow: "2",
                                            }}
                                        />
                                        <Skeleton
                                            sx={{
                                                marginLeft: "8px",
                                                gridColumn: "3",
                                                gridRow: "3",
                                            }}
                                        />
                                    </Paper>
                                ))}
                            </>
                        ) : (
                            <p>
                                <i>No results</i>
                            </p>
                        )}
                    </Box>
                    <Box sx={{ flex: "1 1 auto" }}>
                        <Outlet />
                    </Box>
                </Box>
            </Box>
        </>
    );
}

/*

 


*/
