import { waitForAxiosInstance } from "../HttpCommon";
import { Config } from "../HttpCommon";
import { AxiosError, AxiosInstance, AxiosResponse } from "axios";

export function doBPRefresh(): Promise<any> {
    console.log("doBPRefresh");
    return waitForAxiosInstance().then((inst: AxiosInstance) => {
        return inst.post("/refresh", {
            params: {
                refresh: true
            }
        }, { authRequired: false, withCredentials: true } as Config)
            .then((response: AxiosResponse) => {
                if (!response.hasOwnProperty("data")) {
                    console.log(response);
                    return Promise.reject(Error("Shit data"));
                }

                if (response.data.code < 0) {
                    return Promise.reject(Error(response.data.desc));
                }
                return response.data;
            })
            .catch((e: Error) => {
                if (e instanceof AxiosError) {
                    return Promise.reject(new Error("Network error.  Please retry.  If issue persists, contact BancPass support."));
                } else {
                    return Promise.reject(e);
                }
            });
        });
}
