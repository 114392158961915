import {
    Paper,
    Typography,
    Box
} from "@mui/material";
import { forwardRef, useEffect, useState } from "react";
import { useQuery, QueryClient } from "@tanstack/react-query";
import { doGetStatementSummary } from "../../bancpass-lib/Network/getStatementSummary";
import { DateTime } from "luxon";
import _ from "lodash";
//import { GridItem } from "react-grid-layout";

const statementSummaryQuery = () => ({
    queryKey: ["statement_summary"],
    queryFn: () => doGetStatementSummary(),
  });
//{style, className, onMouseDown, onMouseUp, onTouchEnd, children, ...props}, ref)
const DashboardBalance = forwardRef(function DashboardBalance(props: {
                    children?: React.ReactNode, 
                    editing?: boolean | undefined }, 
                    ref) {
    const [ balance, setBalance ] = useState("0.00");
    const [ lastPaymentAmount, setLastPaymentAmount ] = useState("0.00");
    const [ lastPaymentDate, setLastPaymentDate ] = useState("unknown");
    const { data: summary, isLoading, isFetching } = useQuery(statementSummaryQuery());
    //const { style, className, onMouseDown, onMouseUp, onTouchEnd, children, ...otherProps } = props;
    const { children, editing, ...otherProps } = props;

    useEffect(() => {
        if (summary) {
            
            setBalance(summary.balance.amount.toFixed(2));
            setLastPaymentAmount(summary.last_payment_amount.amount.toFixed(2));
            setLastPaymentDate(DateTime.fromSeconds(summary.last_payment_time).toRelative() || "Unknown");  
        }
    },[summary]);

    return (
        <Paper {...otherProps} ref={ref as React.RefObject<HTMLDivElement>} 
                sx={{ display: "flex", flexDirection: "column", overflow: "hidden" }}>
          
            <Typography align="center">Current Balance:</Typography>
            <Typography variant="h4" align="center">${balance}</Typography>
            <Box sx={{ flexGrow: "1" }} />

            <Typography align="center">Last Payment:</Typography>
            <Box sx={{ textAlign: "center", width: "100%" }}>
                <Typography component="span" variant="h5">${lastPaymentAmount}</Typography>&nbsp;
                <Typography>{lastPaymentDate}</Typography>
            </Box>
            
            { children }

        </Paper>
    );
});

export default DashboardBalance;