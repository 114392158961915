import { useState, useCallback, MouseEvent, useEffect } from "react";
import { Link, useNavigate, Outlet } from "react-router-dom";
import {
  AppBar,
  Badge,
  Box,
  Toolbar,
  LinearProgress,
  IconButton,
  Menu,
  MenuItem,
  Typography
} from "@mui/material";

import { useIdleTimer } from 'react-idle-timer';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';

import styled from "@emotion/styled";

import { useAuth, doBPRefresh, db } from "../../bancpass-lib";
import { useNavigationContext } from "./NavigationContext";
import { NavigationContext } from "./NavigationContext";
import { DateTime } from "luxon";
import { DBInfo } from "../../bancpass-lib/util/DBDexie";
import { useLiveQuery } from "dexie-react-hooks";
import { doGetTollPoints } from "../../bancpass-lib/Network/getTollPoints";

//const drawerWidth = 240;

const FleetPassLogo = styled('img')(({ theme }) => ({
  paddingTop: '8px',
  paddingBottom: '8px',
  width: '100px',
  content: `url(${process.env.REACT_APP_PUBLIC_URL}/fleetpass_hdr.svg)`
}))



export default function AppNavigationBar() {
  const auth = useAuth();
  const dbInfo = useLiveQuery(() => db.dbinfo.get(1));

  const navigate = useNavigate();
  const navContext = useNavigationContext();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  const [state, setState] = useState<string>('Active')
  const [count, setCount] = useState<number>(0)
  const [remaining, setRemaining] = useState<number>(0)

  const onIdle = () => {
    setState('Idle')
  }

  const onActive = () => {
    setState('Active')
  }

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    onActive,
    throttle: 500
  })

  useEffect(() => {
    
    const interval = setInterval(() => {
      if (auth?.authTokenIssued && (Date.now() - auth.authTokenIssued) > 60*1000*5) {
        doBPRefresh().then((response: any) => {
          auth?.setIsAuthenticated(true);
          auth?.setAuthToken(response.response.auth_token);
          auth?.setAuthTokenIssued(Date.now());
          auth?.setAuthDoneRefresh(true);
          
        }).catch((e: Error) => {
          auth?.setIsAuthenticated(false);
          auth?.setAuthToken(null);
          auth?.setAuthTokenIssued(null);
          console.log(e.message);
          navigate('/login');
        });
      }
      setRemaining(Math.ceil(getRemainingTime() / 1000))
    }, 5000)

    return () => {
      clearInterval(interval)
    }
  })

  /* This is here because it has to be inside the Router.  */
  /* Not a super logical place for it, but it will do. */
  /* This will run once on starting the app and see if we have a usable */
  /* refresh token */
  
  useEffect(() => {
    if (auth?.authTokenIssued == null || (Date.now()-auth?.authTokenIssued) < 600) {
      return;
    }  
    /*
    
    */
  },[]); // eslint-disable-line react-hooks/exhaustive-deps

  // it seems useEffect is how you're "supposed" to do "just run once when the app starts"
  // stuff, but it's also not really it's purpose, so gotta disable this warning  

  useEffect(() => {
    if (!auth?.isAuthenticated) {
      console.log("Not Authenticated Yet");
      return;
    }   
    let last_updated = null;
    if (typeof dbInfo?.lastUpdatedTollPoints == "number") {
      last_updated = dbInfo?.lastUpdatedTollPoints;
    }

    doGetTollPoints(last_updated).then((data) => {   
      db.tollpoints.bulkPut(data);
      db.dbinfo.put({id: 1, lastUpdatedTollPoints: DateTime.now().toSeconds()});
    });

  },[auth?.isAuthenticated]);
  return (
    <>
    <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 2 }}>

    <Toolbar variant="dense">
      <FleetPassLogo/>
      <Typography variant="h5" sx={{paddingLeft: "128px", paddingRight: "24px", fontWeight: "bold"}}>{navContext?.pageTitle}</Typography>
      {navContext?.pageButtons}
      <Box sx={{ flexGrow: 1 }}>
          </Box>
      {useAuth()?.isAuthenticated && (
            <div>
              <IconButton
                size="large"
                aria-label="tickets"
                aria-controls="menu-appbar"
                aria-haspopup="false"
                color="inherit"
              >
                <Badge badgeContent="0" color="success" overlap="circular" style={{  transform: 'translate(30px, 10px)'}}/>
                <MailIcon />
              </IconButton>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircleIcon />
                <Typography sx={{paddingLeft:"8px"}}>{auth?.userLoginName}</Typography>
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'center',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem component={Link} to={'/logout'} onClick={handleClose}>Logout</MenuItem>
              </Menu>
            </div>
          )}
    </Toolbar>
      </AppBar>
</>
  );
//                <MenuItem onClick={handleClose}>Account</MenuItem-->



}