import React from 'react';
import ReactDOM from 'react-dom';
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css' 
import './index.css';

import reportWebVitals from './reportWebVitals';
import { setupBPAuthLib, globalAxiosConfig } from './bancpass-lib';
import axios from "axios";
import { ThemeProvider } from '@mui/material/styles';
import { router } from "./App";
import { RouterProvider } from "react-router-dom";
import { theme } from "./components/ui/Theme/Themes";
import {RemoveScrollBar} from 'react-remove-scroll-bar';
import CssBaseline from '@mui/material/CssBaseline';

setupBPAuthLib(axios.create(globalAxiosConfig), "FLEETPASS", router.navigate);
/* React 18 
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
*/

ReactDOM.render(
  <React.StrictMode>
    <RemoveScrollBar />
    <ThemeProvider theme={theme}>
      <CssBaseline/>
      <RouterProvider router={router} />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
