import { ReactNode, useState, useMemo, useEffect } from "react";
import { AuthContext } from './AuthContext';
import React from "react";

type AuthProviderProps = {
  children?: ReactNode;
}

function parseLong(str: string | null): number | null {
  if (!str) {
    return null;
  }
  return parseInt(str, 10);
}

export function AuthProvider({ children }:AuthProviderProps ): JSX.Element {
  const [isAuthenticated, setIsAuthenticated] = useState(localStorage.getItem(process.env.REACT_APP_BASESTORAGE+'.isAuthenticated') === 't');
  const [authToken, setAuthToken] = useState<string | null>(localStorage.getItem(process.env.REACT_APP_BASESTORAGE+'.authToken'));
  const [authTokenIssued, setAuthTokenIssued] = useState<number | null>(parseLong(localStorage.getItem(process.env.REACT_APP_BASESTORAGE+'.authTokenIssued')));
  const [userLoginName, setUserLoginName] = useState<string | null>(localStorage.getItem(process.env.REACT_APP_BASESTORAGE+'.userLoginName'));
  const [userLoginRoles, setUserLoginRoles] = useState<string[] | null>(JSON.parse(localStorage.getItem(process.env.REACT_APP_BASESTORAGE+'.userLoginRoles')??'[]'));

  const [userLoginId, setUserLoginId] = useState<number | null>(parseLong(localStorage.getItem(process.env.REACT_APP_BASESTORAGE+'.userLoginId')));
  const [authDoneRefresh, setAuthDoneRefresh] = useState<boolean>(false);

  useEffect(() => {
    localStorage.setItem(process.env.REACT_APP_BASESTORAGE+'.isAuthenticated', isAuthenticated ? 't' : 'f');
    if (authToken) {
      localStorage.setItem(process.env.REACT_APP_BASESTORAGE+'.authToken', authToken);
    } else {
      localStorage.removeItem(process.env.REACT_APP_BASESTORAGE+'.authToken');
    }
    if (authTokenIssued) {
      localStorage.setItem(process.env.REACT_APP_BASESTORAGE+'.authTokenIssued', authTokenIssued.toString());
    } else {
      localStorage.removeItem(process.env.REACT_APP_BASESTORAGE+'.authTokenIssued');
    }

    if (userLoginId) {
      localStorage.setItem(process.env.REACT_APP_BASESTORAGE+'.userLoginId', userLoginId.toString());
    }

    if (userLoginName) {
      localStorage.setItem(process.env.REACT_APP_BASESTORAGE+'.userLoginName', userLoginName);
    }

    if (userLoginRoles) {
      localStorage.setItem(process.env.REACT_APP_BASESTORAGE+'.userLoginRoles', JSON.stringify(userLoginRoles));
    }
  }, [isAuthenticated, authToken, authTokenIssued, userLoginName, userLoginId, userLoginRoles]);

    const providerValue = useMemo(() => ({
        isAuthenticated, setIsAuthenticated,
        authToken, setAuthToken,
        authTokenIssued, setAuthTokenIssued,
        userLoginName, setUserLoginName,
        userLoginId, setUserLoginId,
        authDoneRefresh, setAuthDoneRefresh,
        userLoginRoles, setUserLoginRoles
    }), [isAuthenticated, authToken, authTokenIssued, userLoginName, userLoginId, authDoneRefresh, userLoginRoles]);
  return <AuthContext.Provider value={providerValue}>{children}</AuthContext.Provider>;
};
