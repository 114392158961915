import {
    AxiosError,
    AxiosInstance,
    AxiosRequestConfig,
    AxiosResponse,
} from "axios";
import { axiosInstance, Config, setAxiosInstance } from "./HttpCommon";
import { BPDexie } from "./util/DBDexie";

export { useAuth } from "./AuthContext";
export { AuthProvider } from "./AuthProvider";
export { globalAxiosConfig } from "./HttpCommon";
export { doGetTrips } from "./Network/getTrips";
export { doHandleToken } from "./Network/handleToken";
export { doBPLogin } from "./Network/Login";
export { doBPLogout } from "./Network/Logout";
export { doBPRefresh } from "./Network/Refresh";

export type {
    Money,
    Plate,
    StatementRow,
    StatementSummary,
    Toll,
    TollPoint,
    Trip,
    User,
    Vehicle,
} from "./Types";

var accountType: string;
var currentId: number;

export const db = new BPDexie();

export function setupBPAuthLib(
    inst: AxiosInstance,
    aType: string,
    navigate: Function
) {
    setAxiosInstance(inst);
    accountType = aType;
    currentId = Math.floor(100000000 + Math.random() * 900000000);
    // below optional chain is fine as we know we just set it a few lines above.
    axiosInstance?.interceptors.request.use(
        (axiosConfig: AxiosRequestConfig): AxiosRequestConfig => {
            const config = axiosConfig as Config;
            config.data.id = currentId.toString();
            currentId++;
            if (config.authRequired === false) {
                config.data.device = { account_type: accountType };
            } else {
                //console.log("Using Token("+config.url+"): "+localStorage.getItem(process.env.REACT_APP_BASESTORAGE+".authToken"));
                config.data.device = {
                    account_type: accountType,
                    auth_token: localStorage.getItem(
                        process.env.REACT_APP_BASESTORAGE + ".authToken"
                    ),
                };
            }
            return config;
        }
    );

    axiosInstance?.interceptors.response.use(
        (axiosResponse: AxiosResponse): AxiosResponse => {
            console.log(axiosResponse.headers);
            return axiosResponse;
        },
        (error: AxiosError): any => {
            // fixme be smarter here!!!
            console.log(error.response?.status);
            console.log(error);
            if (
                error.response &&
                error.response.status > 400 &&
                error.response.status < 500
            ) {
                localStorage.removeItem(
                    process.env.REACT_APP_BASESTORAGE + ".isAuthenticated"
                );
                localStorage.removeItem(
                    process.env.REACT_APP_BASESTORAGE + ".authToken"
                );
                localStorage.removeItem(
                    process.env.REACT_APP_BASESTORAGE + ".authTokenIssued"
                );
                localStorage.removeItem(
                    process.env.REACT_APP_BASESTORAGE + ".userLoginId"
                );
                localStorage.removeItem(
                    process.env.REACT_APP_BASESTORAGE + ".userLoginName"
                );
                window.location.href =
                    process.env.REACT_APP_PUBLIC_URL + "/login";
                return null;
            }
            throw error;
        }
    );
}
