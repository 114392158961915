// db.ts
import Dexie, { Table } from 'dexie';
import { DateTime } from 'luxon';
import { TollPoint, Vehicle, User } from '../Types';

export interface DBInfo {
  id: number,
  lastUpdatedTollPoints: number
}

export class BPDexie extends Dexie {
  // 'friends' is added by dexie when declaring the stores()
  // We just tell the typing system this is the case
  tollpoints!: Table<TollPoint>; 
  dbinfo!: Table<DBInfo>;
  vehicles!: Table<Vehicle>;
  users!: Table<User>;

  constructor() {
    super('bpDatabase');
    this.version(4).stores({
      tollpoints: 'id, lat, long, desc_s, desc_m, desc_l, authority', // Primary key and indexed props
      vehicles: 'id, plate, year, make, model, user_def, driver_id, active',
      users: 'id, user_def, email_address, first_name, last_name, *role, deleted',
      dbinfo: 'id, lastUpdatedTollPoints',
    });
  }
}

