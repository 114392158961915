import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { User, useAuth } from "../../bancpass-lib";
import { getDefaultUser } from "../../bancpass-lib/Network/getUserLogins";
import { doUpdateUserLogin } from "../../bancpass-lib/Network/putUserLogin";

import { useQuery } from "@tanstack/react-query";
import { useNavigationContext } from "../AppNavigationBar/NavigationContext";
import { getUser } from "./getUser";

import DeleteIcon from "@mui/icons-material/Delete";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    IconButton,
    InputAdornment,
    Paper,
    TextField,
} from "@mui/material";
import { queryClient } from "../../App";

const fetchUser = async (
    id: number | string | undefined
): Promise<User | null> => {
    let _id = id;
    if (typeof _id == "undefined") {
        throw new Error("Invalid ID");
    } else if (typeof _id == "string") {
        if (_id === "create") {
            return getDefaultUser();
        }
        _id = parseInt(_id, 10);
    }

    return getUser(_id);
};

export function UserCard(): any {
    const { userLoginId } = useParams();
    const [changed, setChanged] = useState(false);

    const { data, isLoading, isFetching, isError, status, error } = useQuery({
        queryKey: ["users", userLoginId],
        queryFn: () => fetchUser(userLoginId),
        enabled: Boolean(userLoginId),
    });
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [passwordDialogOpen, setPasswordDialogOpen] = useState(false);
    const [confirmNoLoginDialogOpen, setConfirmNoLoginDialogOpen] =
        useState(false);

    const [user, setUser] = useState<User | null | undefined>(data);
    const navigate = useNavigate();
    const navContext = useNavigationContext();
    const auth = useAuth();
    const [newPassword, setNewPassword] = useState("");
    const [newPassword2, setNewPassword2] = useState("");

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
    };

    useEffect(() => {
        (async function () {
            setUser(data ? data : getDefaultUser());
        })();
    }, [data]);

    useEffect(() => {
        (async function () {
            if (
                isFetching ||
                isLoading ||
                user === null ||
                user === undefined
            ) {
                setChanged(false);
                return;
            }
            if (data?.id != +user?.id) {
                setChanged(false);
                return;
            }
            if (
                data?.first_name !== user?.first_name ||
                data?.last_name !== user?.last_name ||
                data?.email_address !== user?.email_address ||
                !(
                    data?.role.every((x) => user?.role.includes(x)) &&
                    user?.role.every((x) => data?.role.includes(x)) &&
                    data?.role.length === user?.role.length
                )
            ) {
                setChanged(true);
            } else {
                setChanged(false);
            }
        })();
    }, [user, user?.role, data]);

    const openDeleteDialog = () => {
        setDeleteDialogOpen(true);
    };

    const handleDeleteDialogClose = () => {
        setDeleteDialogOpen(false);
    };

    const handlePasswordDialogClose = () => {
        setPasswordDialogOpen(false);
    };

    const handleConfirmNoLoginDialogClose = () => {
        setConfirmNoLoginDialogOpen(false);
    };

    const handleChange = (event: any) => {
        if (user === null || user === undefined) {
            return;
        }
        setUser({ ...user, [event.target.id]: event.target.value });
    };

    const handleCancel = (event: any) => {
        setUser(data ? data : getDefaultUser());
    };

    async function handleSubmit(event: any): Promise<any> {
        if (user === null || user === undefined) {
            return;
        }
        return doUpdateUserLogin(user)
            .then((response: User[]) => {
                queryClient.invalidateQueries();
                if (user?.id == -1) {
                    navigate(`/users/${response[0].id}`);
                }
            })
            .catch((e: Error) => {
                return Promise.reject(e);
            });
    }

    async function handleDelete(event: any): Promise<any> {
        if (user == null || user == undefined) {
            return;
        }

        user.deleted = true;
        return doUpdateUserLogin(user)
            .then((response: User[]) => {
                queryClient.invalidateQueries();
                navigate(`/users`);
            })
            .catch((e: Error) => {
                return Promise.reject(e);
            });
    }

    async function handleConfirmNoLogin(event: any): Promise<any> {
        if (user == null || user == undefined) {
            return;
        }

        return doUpdateUserLogin(user, "")
            .then((response: User[]) => {
                queryClient.invalidateQueries();
                navigate(`/users`);
            })
            .catch((e: Error) => {
                return Promise.reject(e);
            });
    }

    async function handlePasswordChange(event: any): Promise<any> {
        if (user == null || user == undefined) {
            return;
        }

        return doUpdateUserLogin(user, newPassword)
            .then((response: User[]) => {
                queryClient.invalidateQueries();
                navigate(`/users`);
            })
            .catch((e: Error) => {
                return Promise.reject(e);
            });
    }

    async function handleTogglePassword(event: any): Promise<any> {
        if (user == null || user == undefined) {
            return;
        }

        if (user.canlogin) {
            setConfirmNoLoginDialogOpen(true);
        } else {
            setPasswordDialogOpen(true);
        }
    }

    const handleRoleChange = (event: any, checked: boolean) => {
        if (user == null || user == undefined) {
            return;
        }

        let role = [...user.role];
        if (checked) {
            if (!role.includes(event.target.name)) {
                role.push(event.target.value);
            }
        } else {
            role = role.filter(function (item) {
                return item !== event.target.value;
            });
        }
        setUser({ ...user, role: role });
    };

    return (
        <>
            <Paper
                key={user?.id}
                sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "flex-end",
                    gap: "16px",
                    padding: "8px",
                }}
                component="form"
                autoComplete="off"
            >
                <TextField
                    sx={{ flex: "1 0 45%" }}
                    required
                    id="first_name"
                    label="First Name"
                    value={user?.first_name || ""}
                    variant="standard"
                    onChange={handleChange}
                />
                <TextField
                    sx={{ flex: "1 0 45%" }}
                    required
                    id="last_name"
                    label="Last Name"
                    value={user?.last_name || ""}
                    variant="standard"
                    onChange={handleChange}
                />

                <IconButton sx={{ flex: "1 0 1%" }} onClick={openDeleteDialog}>
                    <DeleteIcon />
                </IconButton>

                <TextField
                    sx={{ flex: "1 0 90%" }}
                    required
                    id="email_address"
                    label="Email Address"
                    value={user?.email_address || ""}
                    variant="standard"
                    onChange={handleChange}
                />
                <Box sx={{ display: "flex", flex: "0 0 100%" }}>
                    <Box sx={{ flex: "0 1 50%", alignItems: "flex-end" }}>
                        <FormControl
                            sx={{ m: 3, flex: "1 0 10%", margin: "8px" }}
                            component="fieldset"
                            variant="outlined"
                        >
                            <FormLabel component="legend">Roles</FormLabel>
                            <FormGroup sx={{ flexDirection: "row" }} id="role">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={user?.role.includes(
                                                "ADMIN"
                                            )}
                                            readOnly
                                            disabled={
                                                auth?.userLoginId == user?.id
                                            }
                                            onChange={handleRoleChange}
                                            value="ADMIN"
                                            name="role"
                                        />
                                    }
                                    label="Admin"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={user?.role.includes(
                                                "DRIVER"
                                            )}
                                            onChange={handleRoleChange}
                                            value="DRIVER"
                                            name="role"
                                        />
                                    }
                                    label="Driver"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={user?.role.includes(
                                                "BILLING"
                                            )}
                                            onChange={handleRoleChange}
                                            value="BILLING"
                                            name="role"
                                        />
                                    }
                                    label="Billing"
                                />
                            </FormGroup>
                        </FormControl>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flex: "0 1 50%",
                            flexWrap: "wrap",
                            alignItems: "flex-end",
                            gap: "16px",
                            padding: "8px",
                        }}
                    >
                        <Box sx={{ flex: "1 0 10%" }} />

                        <Button
                            sx={{ flex: "0 0 100px" }}
                            disabled={auth?.userLoginId == user?.id}
                            onClick={handleTogglePassword}
                            variant="contained"
                        >
                            {user?.canlogin ? "Disable Login" : "Set Password"}
                        </Button>
                        <Box sx={{ flex: "0 0 100%" }} />
                        <Box sx={{ flex: "1 0 10%" }} />

                        <Button
                            sx={{ flex: "0 0 100px" }}
                            onClick={handleCancel}
                            disabled={!changed}
                            variant="outlined"
                        >
                            Cancel
                        </Button>
                        <Button
                            sx={{ flex: "0 0 100px" }}
                            onClick={handleSubmit}
                            disabled={!changed}
                            variant="contained"
                        >
                            Save
                        </Button>
                    </Box>
                </Box>
                <Dialog
                    open={deleteDialogOpen}
                    onClose={handleDeleteDialogClose}
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Delete this user?"}
                    </DialogTitle>
                    <DialogContent></DialogContent>
                    <DialogActions>
                        <Button
                            onClick={handleDeleteDialogClose}
                            variant="outlined"
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={handleDelete}
                            variant="contained"
                            autoFocus
                        >
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={passwordDialogOpen}
                    onClose={handlePasswordDialogClose}
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Set Password for " +
                            user?.first_name +
                            " " +
                            user?.last_name}
                    </DialogTitle>
                    <DialogContent>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            value={newPassword}
                            onInput={(e) =>
                                setNewPassword(
                                    (e.target as HTMLInputElement).value
                                )
                            }
                            label="New Password"
                            id="bpLoginPassword"
                            type={showPassword ? "text" : "password"}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={
                                                handleMouseDownPassword
                                            }
                                            edge="end"
                                        >
                                            {showPassword ? (
                                                <VisibilityOff />
                                            ) : (
                                                <Visibility />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            value={newPassword2}
                            onInput={(e) =>
                                setNewPassword2(
                                    (e.target as HTMLInputElement).value
                                )
                            }
                            label="Confirm"
                            id="bpLoginPassword2"
                            type={showPassword ? "text" : "password"}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={
                                                handleMouseDownPassword
                                            }
                                            edge="end"
                                        >
                                            {showPassword ? (
                                                <VisibilityOff />
                                            ) : (
                                                <Visibility />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={handlePasswordDialogClose}
                            variant="outlined"
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={handlePasswordChange}
                            disabled={
                                newPassword.length < 8 ||
                                newPassword !== newPassword2
                            }
                            variant="contained"
                            autoFocus
                        >
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={confirmNoLoginDialogOpen}
                    onClose={handleConfirmNoLoginDialogClose}
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Remove Login from " +
                            user?.first_name +
                            " " +
                            user?.last_name}
                    </DialogTitle>
                    <DialogContent></DialogContent>
                    <DialogActions>
                        <Button
                            onClick={handleConfirmNoLoginDialogClose}
                            variant="outlined"
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={handleConfirmNoLogin}
                            variant="contained"
                            autoFocus
                        >
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
            </Paper>
        </>
    );
}
