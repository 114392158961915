import { createTheme, responsiveFontSizes } from '@mui/material/styles';

export const theme = responsiveFontSizes(createTheme({
    palette: {
      primary: {
        main: '#07075B',
        light: '#ff0000',
        dark: '#070744',
        contrastText: '#ffffff'
      },
      background: {
        default: "#f4f4f4",
      },
    },
    typography: {
      fontFamily: [
        'Open Sans',
      ].join(','),
      htmlFontSize: 10,
    },
    components: {
      MuiDrawer: {
        styleOverrides: {
            root: {
                backgroundColor: "darkgrey"
            }
        }
      },
      MuiListItemText: {
        
      }
      
    }
  }));
  