import { createContext, useContext } from "react";

type AuthContextProps = {
  isAuthenticated: boolean;
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
  authToken: string | null;
  setAuthToken: React.Dispatch<React.SetStateAction<string | null>>;
  authTokenIssued: number | null;
  setAuthTokenIssued: React.Dispatch<React.SetStateAction<number | null>>;
  authDoneRefresh: boolean;
  setAuthDoneRefresh: React.Dispatch<React.SetStateAction<boolean>>;
  userLoginName: string | null;
  setUserLoginName: React.Dispatch<React.SetStateAction<string | null>>;
  userLoginId: number | null;
  setUserLoginId: React.Dispatch<React.SetStateAction<number | null>>;
  userLoginRoles: string[] | null;
  setUserLoginRoles: React.Dispatch<React.SetStateAction<string[] | null>>;
};


export const AuthContext = createContext<AuthContextProps | null>(null);

export const useAuth = (): AuthContextProps | null => useContext(AuthContext);

