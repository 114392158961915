import React, { useContext, createContext, ReactNode, useMemo, useState, useEffect, useCallback } from "react";
import { stringAvatar } from "../UserAdmin/stringAvatar";

type NavigationContextProps = {
    pageTitle: string;
    pageButtons: JSX.Element;
};

type NavigationContextSettersProps = {
    setPageTitle: (title: string) => void;
    setPageButtons: (buttons: JSX.Element) => void;
}

export const NavigationContext = createContext<NavigationContextProps | null>(null);
export const NavigationContextSetters = createContext<NavigationContextSettersProps | null>(null);

export const useNavigationContext = (): NavigationContextProps | null => useContext(NavigationContext);
export const useNavigationSettersContext = (): NavigationContextSettersProps | null => useContext(NavigationContextSetters);


export function NavigationContextProvider({ children }: { children?: ReactNode }): JSX.Element {
    const [pageTitle, setPageTitle_] = useState<string>('');
    const [pageButtons, setPageButtons_] = useState<JSX.Element>(<></>);

      const setPageTitle = useCallback(
        (title: string) => {
          setPageTitle_(title);
          document.title = "FleetPass - "+title;
          setPageButtons_(<></>);
        },
        []
      );

      const setPageButtons = useCallback(
        (buttons: JSX.Element) => {
          setPageButtons_(buttons);
        },
        []
      );

      const contextSetters = useMemo(() => ({ setPageTitle, setPageButtons }), [setPageTitle, setPageButtons])

    const providerValue = useMemo(() => ({ pageTitle, pageButtons }), [pageTitle, pageButtons]);
    return <NavigationContext.Provider value={providerValue}>
        <NavigationContextSetters.Provider value={contextSetters}>
            {children}
        </NavigationContextSetters.Provider>
    </NavigationContext.Provider>;
};

