import { waitForAxiosInstance } from "../HttpCommon";
import { Config } from "../HttpCommon";
import { AxiosError, AxiosInstance } from "axios";
import { Vehicle } from "../Types";

export function getDefaultVehicle(): Vehicle {
    return { id: -1, 
             plate: { country: "US", state: '', number: '' },
             tag: null,
             year: '',
             make: '',
             model: '',
             user_def: '',
             driver_id: null,
             driver_name: null,
             active: true,
             deleted: false,
             changed: false};
}

export async function doGetVehicles(vid: number = -1): Promise<any> {
    return waitForAxiosInstance().then((inst : AxiosInstance) => {
        return inst.post("/getVehicles", {
            params: {
                vehicle_id: vid,
            },
        }, { authRequired: true, withCredentials: true } as Config)
            .then((response: any) => {
                if (response.data.code < 0) {
                    return Promise.reject(new Error(response.data.desc));
                }
                let vehicles: Vehicle[] = response.data.response.vehicles;
                return Promise.resolve(vehicles);
            })
            .catch((e: Error) => {
                console.log(e.message);
                if (e instanceof AxiosError) {
                    return Promise.reject(new Error("Network error.  Please retry.  If issue persists, contact BancPass support."));
                } else {
                    return Promise.reject(e);
                }
            });
    });
}

