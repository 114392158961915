import React from "react";
import { IconButton, Box, Input, Paper, InputProps  } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { theme } from "../Theme/Themes";

/**
 * Material design search bar
 * @see [Search patterns](https://material.io/archive/guidelines/patterns/search.html)
 */
const SearchBar = (props: SearchBarProps) => {
  const inputRef = React.useRef();
  const [value, setValue] = React.useState<string>(props.value as string);
  const { onCancelSearch, onRequestSearch, onKeyUp, onFocus, onBlur, onChange, ...iProps } = props;


  React.useEffect(() => {
    setValue(value);
  }, [value]);

  const handleFocus = React.useCallback(
    (e) => {
      if (onFocus) {
        onFocus(e);
      }
    },
    [onFocus]
  );

  const handleBlur = React.useCallback(
    (e) => {
      setValue((v) => v.trim());
      if (onBlur) {
        onBlur(e);
      }
    },
    [onBlur]
  );

  const handleInput = React.useCallback(
    (e) => {
      setValue(e.target.value);
      if (onChange) {
        onChange(e);
      }
    },
    [onChange]
  );

  const handleCancel = React.useCallback(() => {
    setValue("");
    if (onCancelSearch) {
      onCancelSearch();
    }
  }, [onCancelSearch]);


  const handleRequestSearch = React.useCallback(() => {
    if (onRequestSearch) {
      onRequestSearch(value);
    }
  }, [onRequestSearch, value]);

  const handleKeyUp = React.useCallback(
    (e) => {
      if (e.charCode === 13 || e.key === "Enter") {
        handleRequestSearch();
      } else if (
        (e.charCode === 27 || e.key === "Escape")
      ) {
        handleCancel();
      }
      if (onKeyUp) {
        onKeyUp(e);
      }
    },
    [handleRequestSearch, handleCancel, onKeyUp]
  );

  /*
  React.useImperativeHandle(ref, () => ({
    focus: () => {
      inputRef.current.focus();
    },
    blur: () => {
      inputRef.current.blur();
    },
  }));
  */
  const iconButtonHidden = { transform: "scale(0, 0)", "& > .icon": { opacity: 0, }, };
  const iconButton = {
    position: "absolute",

    color: theme.palette.action.active,
    transform: "scale(1, 1)",

    transition: theme.transitions.create(["transform", "color"], {
      duration: theme.transitions.duration.shorter,
      easing: theme.transitions.easing.easeInOut,
    })
  }
//    
//    marginRight: theme.spacing(-6),


//calc(100% - ${theme.spacing(6 + 4)}px)
  return (
    <Paper sx={{ height: theme.spacing(6), display: "flex", justifyContent: "space-between", }}>
      <div style={{ margin: "auto 16px", width: "100%" }}>
        <Input
          {...iProps}
          inputRef={inputRef}
          onBlur={handleBlur}
          value={value}
          autoComplete="off"
          onChange={handleInput}
          onKeyUp={handleKeyUp}
          onFocus={handleFocus}
          fullWidth
          sx={{width: "100%"}}
          placeholder="Search..."
          disableUnderline
          disabled={props.disabled}
        />
      </div>
      <Box sx={{
        display: "grid",
        margin: "3px"
      }}>
      <IconButton
        onClick={handleRequestSearch}
        sx={(value === "") ? iconButton : iconButtonHidden}
        disabled={props.disabled}
      >
        <SearchIcon className="icon" sx= {{
            transition: theme.transitions.create(["opacity"], {
              duration: theme.transitions.duration.shorter,
              easing: theme.transitions.easing.easeInOut,
            }),
          }}/>
      </IconButton>
      <IconButton
        onClick={handleCancel}
        sx={(value !== "") ? iconButton : iconButtonHidden}
        disabled={props.disabled}
      >
        <ClearIcon className="icon" sx= {{
            transition: theme.transitions.create(["opacity"], {
              duration: theme.transitions.duration.shorter,
              easing: theme.transitions.easing.easeInOut,
            }),
          }}/>
      </IconButton>
      </Box>
    </Paper>
  );
}

interface SearchBarProps extends InputProps {
 
  onCancelSearch: (() => void) | undefined;
  /** Fired when the text value changes. */
  onRequestSearch: ((val: string) => void) | undefined;
  /** Sets placeholder text for the embedded text field. */
};

export { SearchBar };
