import { AxiosError, AxiosInstance } from "axios";
import { Config, waitForAxiosInstance } from "../HttpCommon";
import { Vehicle } from "../Types";

export async function doMergeVehicles(vehicle1_id: number, vehicle2_id: number): Promise<Vehicle> {
    return waitForAxiosInstance().then((inst : AxiosInstance) => {
        return inst
            .post(
                "/mergeVehicles",
                {
                    params: {
                        vehicle1_id: vehicle1_id,
                        vehicle2_id: vehicle2_id,
                    },
                },
                { authRequired: true, withCredentials: true } as Config
            )
            .then((response: any) => {
                if (response.data.code < 0) {
                    return Promise.reject(new Error(response.data.desc));
                }
                let vehicle: Vehicle = response.data.response.vehicle;
                return Promise.resolve(vehicle);
            })
            .catch((e: Error) => {
                console.log(e.message);
                if (e instanceof AxiosError) {
                    return Promise.reject(
                        new Error(
                            "Network error.  Please retry.  If issue persists, contact BancPass support."
                        )
                    );
                } else {
                    return Promise.reject(e);
                }
            });
    });
}

