import { Login } from "@bancpass-bit/bancpass-webapps.ui.login";
import {
  Toolbar
} from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { doBPLogin, useAuth } from "../../../bancpass-lib";
import { doForgotPassword } from "../../../bancpass-lib/Network/forgotPassword";
import { useNavigationSettersContext } from "../../AppNavigationBar/NavigationContext";

const LoginPage = () => {
  const navigate = useNavigate();
  const auth = useAuth();

  const navContext = useNavigationSettersContext();
  useEffect(() => {
      navContext?.setPageTitle("");
    },[navContext]);


  async function useForgotPassword(username: string): Promise<any> {
    return doForgotPassword(username).then((response: any) => {
      return Promise.resolve(response['desc']);
    })
      .catch((e: Error) => {
        console.log(e.message);
        return Promise.reject(e.message);
      });
  }

  async function useSubmitLogin(username: string, password: string): Promise<any> {
    return doBPLogin(username, password).then((response: any) => {
      auth?.setIsAuthenticated(true);
      auth?.setAuthToken(response.response.auth_token);
      auth?.setAuthTokenIssued(Date.now());
      auth?.setUserLoginId(response.response.user.id);
      auth?.setUserLoginName(response.response.user.first_name + " " + response.response.user.last_name);
      auth?.setUserLoginRoles(response.response.user.role);
      navigate('/');
    })
      .catch((e: Error) => {
        console.log(e.message);
        return Promise.reject(e.message);
      });
  }

  return (
    <div style={{ flexGrow: 1 }}>
      <Toolbar />
      <Login onLogin={useSubmitLogin} onForgotPassword={undefined}/>
    </div>
  );
}


 
export default LoginPage;