import { waitForAxiosInstance } from "../HttpCommon";
import { Config } from "../HttpCommon";
import { AxiosError, AxiosInstance } from "axios";

export function doBPLogin(username: string, password: string): Promise<any> {
    return waitForAxiosInstance().then((inst: AxiosInstance) => {
        return inst.post("/login", {
            params: {
                email: username,
                password: password,
                refresh: true
            }
        }, { authRequired: false, withCredentials: true } as Config)
            .then((response: any) => {
                if (response.data.code < 0) {
                    return Promise.reject(new Error(response.data.desc));
                }
                return Promise.resolve(response.data);
            })
            .catch((e: Error) => {
                if (e instanceof AxiosError) {
                    return Promise.reject(new Error("Network error.  Please retry.  If issue persists, contact BancPass support."));
                } else {
                    return Promise.reject(e);
                }
            });
    });
}
