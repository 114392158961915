import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    InputLabel,
    Select,
    FormControl,
    Typography,
    MenuItem,
    TextField,
} from "@mui/material";
import { useCallback, useState } from "react";
import { doPutVehicle } from "../../bancpass-lib/Network/putVehicle";
import { User, Vehicle, db } from "../../bancpass-lib";


let statesList = [
    {abbrev: "AL", name: "Alabama"},
    {abbrev: "AK", name: "Alaska"},
    {abbrev: "AZ", name: "Arizona"},
    {abbrev: "AR", name: "Arkansas"},
    {abbrev: "CA", name: "California"},
    {abbrev: "CO", name: "Colorado"},
    {abbrev: "CT", name: "Connecticut"},
    {abbrev: "DE", name: "Delaware"},
    {abbrev: "DC", name: "District of Columbia"},
    {abbrev: "US", name: "Federal"},
    {abbrev: "FL", name: "Florida"},
    {abbrev: "GA", name: "Georgia"},
    {abbrev: "HI", name: "Hawaii"},
    {abbrev: "ID", name: "Idaho"},
    {abbrev: "IL", name: "Illinois"},
    {abbrev: "IN", name: "Indiana"},
    {abbrev: "IA", name: "Iowa"},
    {abbrev: "KS", name: "Kansas"},
    {abbrev: "KY", name: "Kentucky"},
    {abbrev: "LA", name: "Louisiana"},
    {abbrev: "ME", name: "Maine"},
    {abbrev: "MD", name: "Maryland"},
    {abbrev: "MA", name: "Massachusetts"},
    {abbrev: "MI", name: "Michigan"},
    {abbrev: "MN", name: "Minnesota"},
    {abbrev: "MS", name: "Mississippi"},
    {abbrev: "MO", name: "Missouri"},
    {abbrev: "MT", name: "Montana"},
    {abbrev: "NE", name: "Nebraska"},
    {abbrev: "NV", name: "Nevada"},
    {abbrev: "NH", name: "New Hampshire"},
    {abbrev: "NJ", name: "New Jersey"},
    {abbrev: "NM", name: "New Mexico"},
    {abbrev: "NY", name: "New York"},
    {abbrev: "NC", name: "North Carolina"},
    {abbrev: "ND", name: "North Dakota"},
    {abbrev: "OH", name: "Ohio"},
    {abbrev: "OK", name: "Oklahoma"},
    {abbrev: "OR", name: "Oregon"},
    {abbrev: "PA", name: "Pennsylvania"},
    {abbrev: "RI", name: "Rhode Island"},
    {abbrev: "SC", name: "South Carolina"},
    {abbrev: "SD", name: "South Dakota"},
    {abbrev: "TN", name: "Tennessee"},
    {abbrev: "TX", name: "Texas"},
    {abbrev: "UT", name: "Utah"},
    {abbrev: "VT", name: "Vermont"},
    {abbrev: "VA", name: "Virginia"},
    {abbrev: "WA", name: "Washington"},
    {abbrev: "WV", name: "West Virginia"},
    {abbrev: "WI", name: "Wisconsin"},
    {abbrev: "WY", name: "Wyoming"},
];   
    
export const AddNewVehicleDialog = (props: AddNewVehicleDialogProps) => {
    const [newVehicleLicensePlate, setNewVehicleLicensePlate] = useState('');
    // TODO: default states per-acct
    const [newVehicleLicenseState, setNewVehicleLicenseState] = useState<string>('');
    const [saving, setSaving] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const onChangeVehicleLicensePlate = useCallback((e) => {
      setNewVehicleLicensePlate(e.target.value);
  
    }, [newVehicleLicensePlate]);
  
    const handleAddVehicle = () => {
        let newVehicle: Vehicle = {
            id: -1,
            tag: null,
            make: '',
            model: '',
            year: '',
            user_def: '',
            plate: { country: 'US', state: newVehicleLicenseState, number: newVehicleLicensePlate },
            active: true,
            deleted: false,
            changed: false,
            driver_id: null,
            driver_name: null
        };
        setSaving(true);
        doPutVehicle(newVehicle).then((resp: Vehicle) => {
            db.vehicles.add(resp);
            props.onClose(null, "success");
        }).catch((e) => {
            setErrorMessage(e.toString());
        }).finally(() => {
            setSaving(false);
        });

      //setNewVehicleLicensePlate('');
      //setAddVehicleDialogOpen(false);
    }
  
    return (<Dialog
      open={props.open}
      onClose={props.onClose}
      maxWidth={"lg"}>
      <DialogTitle id="alert-dialog-title">Add Vehicle</DialogTitle>
      { errorMessage?.length > 0 ? <Typography align="center" sx={{color:"red"}}>{errorMessage}</Typography> : <></> }
      <DialogContent sx={{display: "flex", alignItems:"flex-end"}}>
        <FormControl >
        <InputLabel sx={{minWidth:"max-content"}} id="newVehicleLicenseStateLabel">State</InputLabel>
        <Select
          labelId="newVehicleLicenseStateLabel"
          id="newVehicleLicenseState"
          fullWidth={true}
          sx={{width: "200px", marginRight:"12px"}}
          value={newVehicleLicenseState}
          label="State"
          onChange={(e)=> { setNewVehicleLicenseState(e.target.value as string)}}
        >
            <MenuItem key={"00000"} value={""}>Select State</MenuItem>
          { statesList.map((s, i) => {
            return (<MenuItem key={i} value={s.abbrev}>{s.name}</MenuItem>);
  
          })}
        </Select>
        </FormControl>
        <TextField
          variant="outlined"
          margin="normal"
          required
          sx={{marginBottom:"0"}}
          name="newVehicleLicensePlate"
          value={newVehicleLicensePlate}
          onChange={onChangeVehicleLicensePlate}
          label="License Plate Number"
          id="newVehicleLicensePlate"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={(e) => { props.onClose(e, "cancel"); }} variant="outlined">Cancel</Button>
        <Button onClick={handleAddVehicle} disabled={ saving || !((newVehicleLicenseState.length >= 2) && (newVehicleLicensePlate.length >= 4))}variant="contained" autoFocus>OK</Button>
      </DialogActions>
    </Dialog>
    );
  
  }
  
  interface AddNewVehicleDialogProps {
   
    open: boolean;
    /** Fired when the text value changes. */
    onClose: ((event: object|null, reason: string) => void);
    /** Sets placeholder text for the embedded text field. */
  };