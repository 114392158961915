import { waitForAxiosInstance } from "../HttpCommon";
import { Config } from "../HttpCommon";
import { AxiosError, AxiosInstance } from "axios";
import { DateTime } from "luxon";
import { TollPoint } from "../Types";


export async function doGetTollPoints(last_updated: number | null): Promise<TollPoint[]> {
    return waitForAxiosInstance().then((inst : AxiosInstance) => {
        
        return inst.post("/getTollPoints", {
            params: {
                last_updated: last_updated?.toString(),
            },
        }, { authRequired: true, withCredentials: true } as Config)
            .then((response: any) => {
                if (response.data.code < 0) {
                    return Promise.reject(new Error(response.data.desc));
                }
                let tollpoints: TollPoint[] = response.data.response.toll_points;
                return Promise.resolve(tollpoints);
            })
            .catch((e: Error) => {
                console.log(e.message);
                if (e instanceof AxiosError) {
                    return Promise.reject(new Error("Network error.  Please retry.  If issue persists, contact BancPass support."));
                } else {
                    return Promise.reject(e);
                }
            });
    });
}