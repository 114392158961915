import {
    Toolbar,
    Box,
} from "@mui/material";

import DownloadIcon from '@mui/icons-material/Download';
import OpenIcon from '@mui/icons-material/OpenInBrowser';
import { useEffect } from "react";
import { useNavigationSettersContext } from "../AppNavigationBar/NavigationContext";
import { DocumentRow } from "./DocumentRow";


const DocumentPage = () => {
    const navContext = useNavigationSettersContext();

    useEffect(() => {
        navContext?.setPageTitle("Documents");
        
      },[navContext]);

    return (<>
        <Box className="pageRoot" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
          <Toolbar sx={{ flex: "0 0 1px" }} />
          <Box sx={{ width: "100%", flexShrink: "1", display: "flex", gap: "12px", padding: "8px", flexDirection: "column", overflowY: "auto" }}>
          <DocumentRow title="Taglinker Instructions" description="Print this document to give out to drivers to perform linking tags to their vehicle."
                loader="taglinker_instructions"/>

          </Box>
                   
          </Box>
          
    </>);
}
 
export default DocumentPage;