import {
    Toolbar
} from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { doBPLogout, useAuth } from "../../../bancpass-lib";
import { useNavigationSettersContext } from "../../AppNavigationBar/NavigationContext";

const LogoutPage = () => {

    const navContext = useNavigationSettersContext();
    useEffect(() => {
        navContext?.setPageTitle("");
      },[navContext]);
  

    const auth = useAuth();
    const navigate = useNavigate();
    useEffect(() => {
        doBPLogout().then((response: any) => {
            auth?.setIsAuthenticated(false);
            auth?.setAuthToken(null);
            auth?.setAuthTokenIssued(null);

            navigate('/login');
        })
            .catch((e: Error) => {
                auth?.setIsAuthenticated(false);
                auth?.setAuthToken(null);
                auth?.setAuthTokenIssued(null);
                navigate('/login');

            });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <Toolbar />
            <h1>Logging Out...</h1>
        </div>
    );
}
 
export default LogoutPage;
