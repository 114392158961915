import {
    Toolbar,
    IconButton,
    Tooltip,
    Box
} from "@mui/material";
import { forwardRef, useCallback, useEffect, useRef, useState } from "react";
import { useNavigationSettersContext } from "../AppNavigationBar/NavigationContext";

import _ from "lodash";

import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import SouthEastIcon from '@mui/icons-material/SouthEast';
import MenuIcon from '@mui/icons-material/Menu';


import { Layout, Responsive, WidthProvider } from "react-grid-layout";
import DashboardBalance from "./DashboardBalance";
const ResponsiveReactGridLayout = WidthProvider(Responsive);

type ResizeHandleAxis = 's' | 'w' | 'e' | 'n' | 'sw' | 'nw' | 'se' | 'ne';

const MyHandle = forwardRef(function MyHandle(props: {
    children?: React.ReactNode }, 
    ref) {
  const { children, ...otherProps } = props;

  return (
    <div {...props} ref={ref as React.RefObject<HTMLDivElement>} className={`react-resizable-handle-se react-resizable-handle`}
      
    >
        <SouthEastIcon/>
        </div>);
    //className={`react-resizable-handle react-resizable-handle-se`}
  
});

interface DashboardItem {
    x: number;
    y: number;
    w: number;
    h: number;
    i: string;
    config: string;
}


const defaultPanel : Layout = {x:0,y:0,w:4,h:2,i:"0"}
  
const Dashboard = () => {
    const navContext = useNavigationSettersContext();
   
    const [ editMode, setEditMode ] = useState(false);
    const [ layout, _setLayout ] = useState<Layout[]>([{...defaultPanel, i:Math.floor(0x10000000 + Math.random() * 0x8FFFFFFF).toString(16)}]);
    const layoutRef = useRef(layout);
    const setLayout = (newLayout: Layout[]) => {
        layoutRef.current = newLayout;
        _setLayout(newLayout);
    };
    const savedLayoutRef = useRef([...layout]);

    const handleEdit = useCallback(() => {
        setEditMode(true);
        savedLayoutRef.current = [...layoutRef.current];
    }, []);

    const handleCancel = useCallback(() => {
        setLayout([...savedLayoutRef.current]);
        setEditMode(false);
    }, []);

    const handleSave = useCallback(() => {
        setEditMode(false);
    }, []);


    const handleAdd = useCallback(() => {
        let newLayout = [...layoutRef.current];
        newLayout.push({...defaultPanel, y: layout.length*2, i:Math.floor(0x10000000 + Math.random() * 0x8FFFFFFF).toString(16)});
        setLayout(newLayout);
    }, [layout.length]);

    function handleRemove(id: string) {
        let newLayout = layoutRef.current.filter(value => value.i !== id);
        setLayout(newLayout);
    }

    function handleChangeWidget(id: string) {


    }

    const onLayoutChange = (currentLayout: ReactGridLayout.Layout[], allLayouts: ReactGridLayout.Layouts) => {
        setLayout([...currentLayout]);
    };

    const generateDOM = () => {        
        return _.map(layout, function (l: Layout) {
                        
                        return (
                            <DashboardBalance key={l.i} editing={editMode}>
                                { editMode ? 
                                <Tooltip title="Change Widget"
                                sx={{ }}>
                                <MenuIcon sx={{cursor: "pointer", position: "absolute", top: "0", left: "0"}} onClick={(e)=>{handleChangeWidget(l.i)}}/>
                                </Tooltip>
                                    : <></>
                                }
                                <CloseIcon className="react-delete-handle" onClick={(e) => { handleRemove(l.i);}}/>
                                </DashboardBalance>
                        );
                    });
    }

    useEffect(() => {        
        navContext?.setPageTitle("Dashboard");
        
        if (editMode) {
            navContext?.setPageButtons(<>
                <Tooltip title="Add Panel"><IconButton
                    size="large"
                    onClick={handleAdd}
                    color="inherit">
                    <AddIcon />
                </IconButton></Tooltip>
                <Tooltip title="Save Changes"><IconButton
                    size="large"
                    onClick={handleSave}
                    color="inherit">
                    <SaveIcon />
                </IconButton></Tooltip>
                <Tooltip title="Discard Changes"><IconButton
                    size="large"
                    onClick={handleCancel}
                    color="inherit">
                    <CancelIcon />
                </IconButton></Tooltip>

            </>);
        } else {
            navContext?.setPageButtons(<Tooltip title="Edit Dashboard"><IconButton
                size="large"
                onClick={handleEdit}
                color="inherit">
                <EditIcon />
            </IconButton></Tooltip>);
        }
    }, [navContext, editMode, handleSave, handleAdd, handleEdit, handleCancel]);

    /*
   
  */
    return (
        <Box className="pageRoot" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, }}>
            <Toolbar />

         
                <ResponsiveReactGridLayout
                    rowHeight={100}
                    cols= {{ lg: 12 }}
                    breakpoints = {{lg: 1200}}
                    layouts={{lg: layout}}
                    onLayoutChange={onLayoutChange}
                    isDraggable={editMode}
                    isResizable={editMode}
                    // WidthProvider option
                    resizeHandles={["se"]}
                    resizeHandle={(handleAxis: ResizeHandleAxis, ref: React.Ref<HTMLDivElement>) =>
                        <MyHandle ref={ref} />
                      }
                    measureBeforeMount={true}
                    // I like to have it animate on mount. If you don't, delete `useCSSTransforms` (it's default `true`)
                    // and set `measureBeforeMount={true}`.
                    //useCSSTransforms={mounted}
                > 
                    { generateDOM() } 
               </ResponsiveReactGridLayout>
        </Box>
    );
}
 
/*


*/

export default Dashboard;