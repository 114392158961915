import { matchSorter } from 'match-sorter';
import { doGetVehicles, getDefaultVehicle } from '../../bancpass-lib/Network/getVehicles';
import { db, Vehicle } from '../../bancpass-lib';
var sortBy = require('sort-by');


export async function getVehicles(query: string | null): Promise<Vehicle[]> {
  return doGetVehicles().then((response: Vehicle[]) => {
    //var vehicles;
    //if (!vehicles)
    let vehicles = response;
    db.vehicles.bulkPut(vehicles);

    if (query) {
      vehicles = matchSorter(vehicles, query, { keys: ["driver_name", "user_def", "plate.number"] });
    }
    return Promise.resolve(vehicles.sort(sortBy("plate.state", "plate.number")));
  }).catch((e: Error) => {
    return Promise.reject(e);
  });
}

export async function createVehicle() {
  let vehicle: Vehicle = getDefaultVehicle();
  let vehicles = await getVehicles(null);
  vehicles.unshift(vehicle);
  return vehicle;
}

export async function getVehicle(id: number) {
  console.log("getvehicle()");
  return doGetVehicles(id).then((response: Vehicle[]) => {
    let vehicle = response[0];
    return Promise.resolve(vehicle);
  }).catch((e: Error) => {
    return Promise.reject(e);
  });
}

export async function updateVehicle(id: number, updates: Vehicle) {
  let vehicles = await getVehicles(null);
  let vehicle = vehicles.find((vehicle) => vehicle.id === id);
  if (!vehicle) throw new Error("No contact found for"+ id.toString());
  Object.assign(vehicle, updates);
  //await set(contacts);
  return vehicle;
}

export async function deleteVehicle(id: number) {
  let vehicles = await getVehicles(null);
  let index = vehicles.findIndex((vehicle) => vehicle.id === id);
  if (index > -1) {
    vehicles.splice(index, 1);
    return true;
  }
  return false;
}

