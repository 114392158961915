import { Button, Toolbar, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { doHandleToken, useAuth } from "../../../bancpass-lib";
import { useNavigationSettersContext } from "../../AppNavigationBar/NavigationContext";

const ForgotPasswordPage = () => {
    const navigate = useNavigate();
    const auth = useAuth();
    const queryParameters = new URLSearchParams(window.location.search);
    const [errorString, setErrorString] = useState<string | null>(null);

    const token = queryParameters.get("t");

    const navContext = useNavigationSettersContext();
    useEffect(() => {
        navContext?.setPageTitle("");
    }, [navContext]);

    /*
    async function useForgotPassword(username: string): Promise<any> {
        return doForgotPassword(username)
            .then((response: any) => {
                return Promise.resolve(response["desc"]);
            })
            .catch((e: Error) => {
                console.log(e.message);
                return Promise.reject(e.message);
            });
    }
*/
    async function handlePasswordReset(event: any): Promise<any> {
        return doHandleToken(token ?? "notoken", true)
            .then((response: any) => {
                if (response.code == 1) {
                    auth?.setIsAuthenticated(true);
                    auth?.setAuthToken(response.response.auth_token);
                    auth?.setAuthTokenIssued(Date.now());
                    auth?.setUserLoginId(response.response.user.id);
                    auth?.setUserLoginName(
                        response.response.user.first_name +
                            " " +
                            response.response.user.last_name
                    );
                    auth?.setUserLoginRoles(response.response.user.role);
                    navigate("/");
                } else {
                    setErrorString(response.desc);
                }
                //navigate(`/login`);
            })
            .catch((e: Error) => {
                setErrorString(e.message);
                return Promise.reject(e);
            });
    }

    return (
        <div style={{ flexGrow: 1 }}>
            <Toolbar />
            <div style={{ textAlign: "center" }}>
                <h4>Forgot Password?</h4>
                {errorString ? (
                    <Typography>{errorString}</Typography>
                ) : (
                    <Typography>
                        Click
                        <Button onClick={handlePasswordReset}>HERE</Button> to
                        reset your password.
                    </Typography>
                )}
            </div>
        </div>
    );
};

export default ForgotPasswordPage;
