import { AxiosError, AxiosInstance } from "axios";
import { Config, waitForAxiosInstance } from "../HttpCommon";

export function doHandleToken(token: string, auth: boolean): Promise<any> {
    return waitForAxiosInstance().then((inst: AxiosInstance) => {
        return inst
            .post(
                "/handleToken",
                {
                    params: {
                        token: token,
                        auth: auth,
                    },
                },
                { authRequired: false, withCredentials: true } as Config
            )
            .then((response: any) => {
                if (response.data.code < 0) {
                    return Promise.reject(new Error(response.data.desc));
                }
                return Promise.resolve(response.data);
            })
            .catch((e: Error) => {
                if (e instanceof AxiosError) {
                    return Promise.reject(
                        new Error(
                            "Network error.  Please retry.  If issue persists, contact BancPass support."
                        )
                    );
                } else {
                    return Promise.reject(e);
                }
            });
    });
}
