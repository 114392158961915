import {
    Paper,
    Box,
    IconButton,
    Tooltip,
    Typography,
    Button,
    Dialog, DialogTitle, DialogContent, DialogActions

} from "@mui/material";

import DownloadIcon from '@mui/icons-material/Download';
import OpenIcon from '@mui/icons-material/OpenInBrowser';
import { useState } from "react";
import { useAuth } from "../../bancpass-lib";

const DocumentRow = (props: DocumentRowProps) => {  
    // FIXME be smarter about auth n stuff
    const { title, description, loader, ...otherProps } = props;
    const [ documentDialogOpen, setDocumentDialogOpen ] = useState(false);
    const auth = useAuth();

    const openDocumentDialog = () => {
        setDocumentDialogOpen(true);
    };

    const handleDocumentDialogClose = () => {
        setDocumentDialogOpen(false);
    };

    const onDownload = () => {
        const link = document.createElement("a");
        link.download = 'true';
        link.target = '_blank';
        link.href = process.env.REACT_APP_BP_BASE_URL+"/documents/" + loader + "?account_type=FLEETPASS&download=true&auth_token=" + auth?.authToken;
        link.click();
      };

    return (<>
        <Paper sx={{ flex: "0 1 auto;", padding: "4px 8px" }}>
            <Typography variant="h4">{title}</Typography>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Typography variant="body1" sx={{ flex: "0 1 100%" }}>{description}</Typography>
                <Tooltip title="Open Document">
                    <IconButton
                        onClick={openDocumentDialog}>

                        <OpenIcon fontSize="large" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Download Document">
                    <IconButton
                        onClick={onDownload}>

                        <DownloadIcon fontSize="large" />
                    </IconButton>
                </Tooltip>
            </Box>
        </Paper>
        <Dialog
            open={documentDialogOpen}
            onClose={handleDocumentDialogClose}
            fullWidth={true}
            maxWidth={"lg"}>
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>

            <DialogContent sx={{ display: "flex", alignItems: "flex-end", flex: "1 1 80vh" }}>
                <object data={process.env.REACT_APP_BP_BASE_URL+"/documents/" + loader + "?account_type=FLEETPASS&auth_token=" + auth?.authToken} type="application/pdf" width="100%" height="100%" />
            </DialogContent>
            <DialogActions>
                <Button onClick={(e) => { setDocumentDialogOpen(false); }} variant="outlined">Close</Button>
            </DialogActions>
        </Dialog>
    </>
      )
  }
  
    
  interface DocumentRowProps  {
      title: string;
      description: string;
      loader: string;
    };
    
  export { DocumentRow };