import { AxiosRequestConfig, AxiosInstance } from "axios";

export interface Config extends AxiosRequestConfig {
  authRequired: boolean | null;
}
export const globalAxiosConfig: Config = {
  baseURL: process.env.REACT_APP_BP_BASE_URL,
  headers: {
    "Content-type": "application/json"
  },
  withCredentials: true,
  authRequired: null
};

export var axiosInstance: AxiosInstance | undefined = undefined;
export function setAxiosInstance(inst: AxiosInstance) {
  axiosInstance = inst;
}

export type authObjectType = {
  authToken: string | null;
  authTokenTime: string | null;
};

var timeout = 1000000;

export async function waitForAxiosInstance(): Promise<AxiosInstance> {
  var start = Date.now();
  return new Promise(waitForVariable);
  function waitForVariable(this: any, resolve : any, reject : any) {
    if (axiosInstance) {
        resolve(axiosInstance);
    } else if (timeout && (Date.now() - start) >= timeout) {
        reject(new Error("timeout"));
    } else {
        setTimeout(waitForVariable.bind(this, resolve, reject), 30);
    }
  }
}









