import { waitForAxiosInstance } from "../HttpCommon";
import { Config } from "../HttpCommon";
import { AxiosError, AxiosInstance } from "axios";
import { DateTime } from "luxon";
import { Trip } from "../Types";



export async function doGetTrips(startTime: number | null, endTime: number | null, vehicle_id: number | null | undefined): Promise<any> {
    
    return waitForAxiosInstance().then((inst : AxiosInstance) => {
        return inst.post("/getTrips", {
            params: {
                start_time: startTime,
                end_time: endTime,
                vehicle_id: vehicle_id,
            },
        }, { authRequired: true, withCredentials: true } as Config)
            .then((response: any) => {
                if (response.data.code < 0) {
                    return Promise.reject(new Error(response.data.desc));
                }
                let trips: Trip[] = response.data.response.trips;
               
                return Promise.resolve(trips);
            })
            .catch((e: Error) => {
                console.log(e.message);
                if (e instanceof AxiosError) {
                    return Promise.reject(new Error("Network error.  Please retry.  If issue persists, contact BancPass support."));
                } else {
                    return Promise.reject(e);
                }
            });
    });
}

