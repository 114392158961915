
import {
  TableCell,
  TableRow
} from "@mui/material";

import { useLiveQuery } from "dexie-react-hooks";
import { DateTime } from "luxon";
import { db, StatementRow, Vehicle } from "../../bancpass-lib";


interface Column {
    id: 'num' | 'type' | 'note' | 'time' | 'vid' | 'amt' | 'bal';
    label: string;
    minWidth?: number;
    align?: 'right' | 'center';
    format?: (row: StatementRow) => string | JSX.Element;
  }
  
export const StatementRowColumns: readonly Column[] = [
    { id: 'num', 
      label: 'Statement Row', 
      minWidth: 80,
      format: (row: StatementRow) => row.num.toString() },
    {
      id: 'time',
      label: 'Time',
      minWidth: 170,
      align: 'center',
      format: (row: StatementRow) => {
        const date = DateTime.fromSeconds(row.time);
        return <>{date.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}<br/>{date.toLocaleString(DateTime.TIME_24_WITH_SECONDS)}</>;
      },
    },
    {
      id: 'type',
      label: 'Type',
      minWidth: 170,
      align: 'center',
      format: (row: StatementRow) => {
        
        return row.type;
      },
    },
    {
      id: 'vid',
      label: 'Vehicle',
      minWidth: 170,
      align: 'center',
    },
    {
      id: 'note',
      label: 'Note',
      minWidth: 200,
      align: 'right',
      format: (row: StatementRow) => {
        return row.note;
      },
    },
    {
        id: 'amt',
        label: 'Amount',
        minWidth: 60,
        align: 'right',
        format: (row: StatementRow) => "$"+row.amt.amount.toFixed(2),
      },
      {
        id: 'bal',
        label: 'Balance',
        minWidth: 60,
        align: 'right',
        format: (row: StatementRow) => "$"+row.bal.amount.toFixed(2),
      },
  ];


//

function formatVehicle(vehicle: Vehicle | undefined) : JSX.Element {
  if (!vehicle) { return <></>; }
  return <>{vehicle.plate.state + "-" + vehicle.plate.number}</>;
}

const StatementTableRow = (props: StatementRowProps) => {
  const vehicle = useLiveQuery(() => db.vehicles.where("id").equals(props.statementRow.vid ? props.statementRow.vid : 0).first());

    return (
        <TableRow hover role="checkbox" tabIndex={-1}>
            {StatementRowColumns.map((column) => {
              console.log(column);
                return (
                    <TableCell sx={{padding: "4px 16px"}} key={column.id+"blah"} align={column.align}>
                        {column.format ? column.format(props.statementRow) :
                            column.id == "vid" ? (formatVehicle(vehicle)) :

                        "Unknown2"
                        
                        }
                    </TableCell>
                );
            })}
        </TableRow>
    )
}

  
interface StatementRowProps  {
    statementRow: StatementRow;
  };
  
export { StatementTableRow };

