
import {
  Link,
  TableCell,
  TableRow
} from "@mui/material";

import { useLiveQuery } from "dexie-react-hooks";
import { DateTime } from "luxon";
import { TollPoint, Trip, Vehicle, db } from "../../bancpass-lib";


interface Column {
    id: 'name' | 'vehicle' | 'time' | 'cost' | 'location';
    label: string;
    minWidth?: number;
    align?: 'right' | 'center';
    format?: (trip: Trip) => string | JSX.Element;
  }
  
export const TollRowColumns: readonly Column[] = [
    { id: 'name', 
      label: 'Toll ID', 
      minWidth: 80,
      format: (trip: Trip) => trip.id.toString() },
    { 
      id: 'vehicle', 
      label: 'Vehicle', 
      minWidth: 100,
     },
    {
      id: 'time',
      label: 'Time',
      minWidth: 170,
      align: 'center',
      format: (trip: Trip) => {
        const date = DateTime.fromSeconds(trip.start_time);
        return <>{date.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}<br/>{date.toLocaleString(DateTime.TIME_24_WITH_SECONDS)}</>;
      },
    },
 
    {
      id: 'location',
      label: 'Location',
      minWidth: 170,
      align: 'right',
    },
    {
        id: 'cost',
        label: 'Cost',
        minWidth: 60,
        align: 'right',
        format: (trip: Trip) => "$"+trip.cost.amount.toFixed(2),
      },
  ];

function formatVehicle(vehicle: Vehicle | undefined) : JSX.Element {
    if (!vehicle) { return <></>; }
    return <>{vehicle.plate.state + "-" + vehicle.plate.number}</>;
}

function formatTollpoint(tollpoint: TollPoint | undefined, openFn: any) : JSX.Element {
    if (!tollpoint || !tollpoint.desc_m) { return <></> }
    const lines = tollpoint.desc_m.split(" at ");
    let text: string;
    if (!lines) {
        return <>{tollpoint.desc_m}</>
    } else {
        return <><Link sx={{cursor: "pointer"}} component="div"  
                    onClick={() => {openFn(tollpoint.desc_m, tollpoint.lat, tollpoint.long)}}>{lines[0]}<br/>{lines[1]}</Link></>;
    }
}

//target="_blank"

const TollRow = (props: TollRowProps) => {
    const tollPoint = useLiveQuery(() => db.tollpoints.where("id").equals(props.trip.tolls[0].toll_point).first());
    const vehicle = useLiveQuery(() => db.vehicles.where("id").equals(props.trip.vehicle_id).first());

    return (
        <TableRow hover role="checkbox" tabIndex={-1}>
            {TollRowColumns.map((column) => {
                return (
                    <TableCell sx={{padding: "4px 16px"}} key={column.id} align={column.align}>
                        {column.format ? column.format(props.trip) :
                            column.id == "location" ? (formatTollpoint(tollPoint, props.mapOpen)) :
                            column.id == "vehicle" ? (formatVehicle(vehicle)) :

                        "Unknown2"
                        
                        }
                    </TableCell>
                );
            })}
        </TableRow>
    )
}

  
interface TollRowProps  {
    trip: Trip;
    mapOpen: (title: string, lat: number, long: number) => void;
  };
  
export { TollRow };

